<template>
    <div class="modal-update-announcement">
        <el-dialog
            :key="'編輯公告'"
            title="編輯公告"
            :visible.sync="isDialogVisible"
            width="50%"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-update-announcement__form form-wrap">
                <div class="modal-update-announcement__form-input form-input" :class="{'error': error.subject}">
                    <label>標題：</label>
                    <el-input v-model="formUpdateNews.subject" autocomplete="off" placeholder="請輸入公告標題"></el-input>
                    <div class="error-msg">必填</div>
                </div>
                <div class="modal-update-announcement__form-input modal-update-announcement__form-input--body form-input" :class="{'error': error.body}">
                    <label>內容：</label>
                    <el-input v-model="formUpdateNews.body" type="textarea" 
                    :autosize="{ minRows: 3, maxRows: 8}" autocomplete="off" placeholder="請輸入公告內容"></el-input>
                    <div class="error-msg">必填</div>
                </div>
                <div class="modal-update-announcement__form-input form-input">
                    <label>類型：</label>
                    <div class="form-radio-wrap">
                        <el-radio v-model="formUpdateNews.category" :label="1">一般</el-radio>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="updateNewsModal()" :disabled="comfirmDisabled">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiUpdateNews, apiGetNewsDetail } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
    data () {
        return {
            formUpdateNews: {
                subject: '',
                body: '',
                category: 1,
            },
            error: {
                subject: '',
                body: '',
            },
            comfirmDisabled: false,
        }
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            default: false,
        },
        newsId: {
            type: Number,
            default: null,
        },
    },
    watch: {
        newsId: function() {
            if(this.newsId != null){
                this.callApiGetNewsDetail();
            }
        },
        watch: {
            formUpdateNews: {
                handler: function (newValue) {
                    if(newValue.subject){
                        this.error.subject = false;
                    }
                    if(newValue.body){
                        this.error.body = false;
                    }
                },
                deep: true,
            },
        },
   },

  methods: {
    // 編輯公告
    updateNewsModal(){
        if(this.checkRequired() ){
            // call api 編輯公告
            this.callApiUpdateNews();
        }
    },
    // 關閉 modal
    modalClose() {
        this.$emit("isUpdateNewsDialogVisible", false);
        if(this.newsId != null){
            this.callApiGetNewsDetail();
        }
    },
    // 取得 公告詳細
    callApiGetNewsDetail() {
        loadingStart();
        apiGetNewsDetail(
            this.newsId
        )
        .then(res=> {
            loadingFinished();
            this.formUpdateNews = res.data.data;
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
        })
    },
    // 編輯公告api
    callApiUpdateNews(){
        loadingStart();
        apiUpdateNews(
            {
                subject: this.formUpdateNews.subject,
                body: this.formUpdateNews.body,
                category: this.formUpdateNews.category,
            },
            this.formUpdateNews.id,
        )
        .then(res=> {
            loadingFinished();
            this.modalClose();
            if(res.data.code == 0){
                this.$notify({
                    title: '編輯公告',
                    message: '編輯公告成功',
                    type: 'success'
                });
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "編輯公告失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
    // 驗證必填欄位
    checkRequired(){
        if(this.formUpdateNews.subject && this.formUpdateNews.subject != ''){
            this.error.subject = false;
        }else{
            this.error.subject = true;
        }
        if(this.formUpdateNews.body && this.formUpdateNews.body != ''){
            this.error.body = false;
        }else{
            this.error.body = true;
        }

        // 驗證所有欄位
        if(this.error.subject == false &&
            this.error.body == false){
            return true;
        }else{
            return false;
        }
    },
  },
}
</script>
<style scoped lang="scss">
.modal-update-announcement {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
    .form-radio-wrap {
        width: 100%;
    }
    &__form-input {
        label {
            white-space: nowrap;
        }
        &--body {
            align-items: flex-start !important;
        }
    }
}
</style>