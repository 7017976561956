<template>
  <div class="member-consume-list layout-grid">
  <!-- 篩選資料 -->
    <div class="filter">
      <div class="left">
        <div class="input-wrap">
          <label>月份：</label>
          <el-date-picker
            :editable="false"
            v-model="formFilter.date"
            type="month"
            :clearable="false"
            value-format="yyyy-MM"
            ref="filterDate"
            placeholder="請輸入月份"
            :picker-options="pickerOptions"
            @focus="pickerFocus()">
          </el-date-picker>
        </div>
      </div>
    </div>
    <!-- 資料列表 -->
    <div class="data-list">
      <el-table
        :data="displayData">
        <el-table-column
          prop="id"
          width="200"
          label="收據編號">
        </el-table-column>
        <el-table-column
          prop="girl.name"
          label="貴妃暱稱">
        </el-table-column>
        <el-table-column
          align="center"
          label="白鑽"
          :formatter="whiteFormatter">
        </el-table-column>
         <el-table-column
          align="center"
          prop="duration"
          label="時長（hr）">
        </el-table-column>
        <el-table-column
          prop="create_at"
          width="200"
          label="消費時間">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[20]"
        :page-size="formFilter.pageSize"
        layout="prev, pager, next, sizes"
        :total="formFilter.totalPage">
      </el-pagination>
    </div>
</div> 
</template>

<script>
import { apiGetUserReceipts } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          var filterDate = new Date();
          filterDate.setMonth(filterDate.getMonth()-6);
          filterDate.setDate(1);
          filterDate.setDate(filterDate.getDate() - 1);
          // 除了當月，需看到前半年報表，最大選擇日期為昨日
          let yesterday = new Date().setDate(new Date().getDate() - 1);
          return time.getTime() >= yesterday || time.getTime() < filterDate.getTime();
        }
      }, 
      formFilter: {
        date: null,
        page: 1,
        pageSize: 20,
        totalPage: 0,
      },
      total: null, 
      startDate: null,  
      endDate: null,
      tableDataList: [],
      currentPage: 1,
      memberDetailId: null, 
      memberDetailDialogVisible: false,
    }
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  mounted(){
    // picker預設日期
    this.formFilter.date = this.defaultDate;

    this.callApiGetUserReceipts();
  },
  components: {
  },
  methods: {
    pickerFocus(){
      // 重新render datepicker
      // this.$refs.filterDate.mountPicker();
    },
    // 資料篩選 查詢 
    filterSearch(){
      this.currentPage = 1;
      // 日期 搜尋
      if(this.formFilter.date != ""){
        this.formFilter.page = 1;
        this.formFilter.totalPage = 1;
        this.callApiGetUserReceipts();
      }
    },
    // 付款金額 欄位format顯示
    priceFormatter(row) {
      return row.price
    },
    // 白鑽 欄位format顯示
    whiteFormatter (row) {
      return "-" + row.fee;
    },
    // 取得 儲值紀錄
    callApiGetUserReceipts(){
      if(!this.userId){
        console.error("userId = null");
      }else{
        loadingStart();

        let param = "";
        // 頁碼/頁數
        param += `page=${this.formFilter.page}&size=${this.formFilter.pageSize}`;

        // 日期
        let year = this.formFilter.date.split('-')[0]
        let month = this.formFilter.date.split('-')[1]
        param += `&year=${year}&month=${month}`;

        apiGetUserReceipts(
          param, this.userId
        )
        .then(res=> {
          loadingFinished();
          if(res.data.code == 0){
            this.tableDataList = res.data.data;
            this.formFilter.totalPage = res.data.paging.total_page * res.data.paging.size;
          }else{
              this.$notify.error({
                  title: "發生錯誤",
                  message: "系統發生非預期錯誤"
              });
          }
        })
        .catch(err=> {
          loadingFinished();
          this.$notify.error({
              title: "發生錯誤",
              message: err.data.msg
          });
        })
      }
    },
    // 切換每頁顯示的數量
    handleSizeChange(val) {
      this.currentPage = 1;
      this.formFilter.pageSize = val;
      this.formFilter.page = 1;
      this.callApiGetUserReceipts();
    },
    // 切換當前頁數
    handleCurrentChange(val) {
      this.currentPage = val;
      this.formFilter.page = val;
      this.callApiGetUserReceipts();
    },
  },
  computed: {
    displayData() {
      return this.tableDataList;
    },
    defaultDate() {
      // 昨天
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let month = yesterday.getMonth()+1;
      if(month < 10){
        month = "0" + month;
      }
      return `${yesterday.getFullYear()}-${month}`;
    }
  },
  watch: {
    'formFilter.date': {
      handler: function () {
        this.filterSearch();
      },
      deep: true,
    },
  }
}
</script>


<style scoped lang="scss">
.member-consume-list {
    &.layout-grid {
      margin: 0;
    }
}
</style>
