<template>
    <div class="modal-add-member">
        <el-dialog
            :key="'新增用戶'"
            title="新增用戶"
            :visible.sync="isDialogVisible"
            width="50%"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-add-member__form form-wrap">
                <div class="modal-add-member__form-input form-input">
                    <label><span class="form-required-text">*</span>角色：</label>
                    <div class="form-radio-wrap">
                        <el-radio v-model="formAddMember.role" :label="2">貴妃</el-radio>
                        <el-radio v-model="formAddMember.role" :label="1">用戶</el-radio>
                    </div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.mobile}">
                    <label><span class="form-required-text">*</span>帳號：</label>
                    <el-input v-model="formAddMember.mobile" autocomplete="off" placeholder="請輸入帳號（手機號碼）"></el-input>
                    <div class="error-msg">必填且需符合手機格式</div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.password}">
                    <label><span class="form-required-text">*</span>密碼：</label>
                    <el-input v-model="formAddMember.password" autocomplete="off" placeholder="請輸入密碼"></el-input>
                    <div class="error-msg">必填且8~16字元</div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.nickname}">
                    <label><span class="form-required-text">*</span>暱稱：</label>
                    <el-input v-model="formAddMember.nickname" autocomplete="off" placeholder="請輸入暱稱"></el-input>
                    <div class="error-msg">必填且1~16字元</div>
                </div>
                <div class="modal-add-member__form-input form-input">
                    <label><span class="form-required-text">*</span>性別：</label>
                    <div class="form-radio-wrap">
                        <el-radio v-model="formAddMember.sex" label="f">女性</el-radio>
                        <el-radio v-model="formAddMember.sex" label="m">男性</el-radio>
                    </div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.birthday}">
                    <label><span class="form-required-text">*</span>生日：</label>
                    <el-date-picker
                        ref="birthdayPicker"
                        v-model="formAddMember.birthday"
                        type="date"
                        :clearable="false"
                        value-format="yyyy-MM-dd"
                        placeholder="請選擇生日"
                        @focus="pickerFocus()">
                    </el-date-picker>
                    <div class="error-msg">必填</div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.email}">
                    <label><span class="form-required-text">*</span>信箱：</label>
                    <el-input v-model="formAddMember.email" autocomplete="off" placeholder="請輸入信箱"></el-input>
                    <div class="error-msg">必填且需符合信箱格式</div>
                </div>
                <div class="modal-add-member__form-input form-input" :class="{'error': error.id_card}">
                    <label><span class="form-required-text">*</span>身分證字號：</label>
                    <el-input v-model="formAddMember.id_card" autocomplete="off" placeholder="請輸入身分證字號"></el-input>
                    <div class="error-msg">必填且需符合身分證格式</div>
                </div>
                <div class="modal-add-member__form-input form-input">
                    <label>推薦碼：</label>
                    <el-input v-model="formAddMember.invite" autocomplete="off" placeholder="請輸入推薦碼"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="addMemberModal()" :disabled="comfirmDisabled">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiCreateUser } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
  data () {
    return {
        formLabelWidth: "50",
        formAddMember: {
            role: 2,
            mobile: '',
            password: '',
            nickname: '',
            sex: 'f',
            birthday: '',
            email: '',
            id_card: '',
            invite: '',
        },
        error: {
            email: false,
            nickname: false,
            password: false,
            id_card: false,
            birthday: false,
            mobile: false,
        },
        comfirmDisabled: true,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    }
  },
  watch: {
    formAddMember: {
        handler: function (newValue) {
            if(newValue.mobile){
                // 開頭不為09
                if(newValue.mobile.toString().indexOf("09") == -1 || newValue.mobile.length !=10){
                    this.error.mobile = true;
                }else{
                    this.error.mobile = false;
                }
            }
            if(newValue.password){
                // 8~16字元
                if(newValue.password.length >=8 && newValue.password.length <=16){
                    this.error.password = false;
                }else{
                    this.error.password = true;
                }
            }
            if(newValue.nickname){
                // 1~16字元
                if( (newValue.nickname.length >=1 && newValue.nickname.length <=16)){
                    this.error.nickname = false;
                }else{
                    this.error.nickname = true;
                }
            }
            if(newValue.email){
                var reEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/; // 判斷email格式
                if(reEmail.test(newValue.email)){
                    this.error.email = false;
                }else{
                    this.error.email = true;
                }
            }
            if(newValue.id_card){
                var reId = /^[A-Z]{1}[1-2]{1}[0-9]{8}$/; // 判斷中華民國身分證格式
                if(reId.test(newValue.id_card)){
                    this.error.id_card = false;
                }else{
                    this.error.id_card = true;
                }
            }
            // 驗證所有欄位
            if(this.error.email == false &&
                this.error.nickname == false &&
                this.error.password == false &&
                this.error.id_card == false &&
                this.error.mobile == false &&
                this.error.birthday == false){
                this.comfirmDisabled = false;
            }else{
                this.comfirmDisabled = true;
            }
        },
        deep: true,
    },
  },
  methods: {
    pickerFocus(){
        // 重新render datepicker
        this.$refs.birthdayPicker.mountPicker();
    },
    // 新增用戶
    addMemberModal(){
        if(this.checkRequired() ){
            // call api 新增用戶
            this.callApiCreateUser();
        }
    },
    // 關閉 modal
    modalClose() {
        this.clearData();
        this.$emit("isAddMemberDialogVisible", false);
    },
    // 清除資料
    clearData(){
        this.formAddMember.role = 2;
        this.formAddMember.mobile = "";
        this.formAddMember.password = "";
        this.formAddMember.nickname = "";
        this.formAddMember.sex = "f";
        this.formAddMember.birthday = "";
        this.formAddMember.email = "";
        this.formAddMember.id_card = "";
        this.formAddMember.invite = "";
        this.error.email = false;
        this.error.nickname = false;
        this.error.password = false;
        this.error.id_card = false;
        this.error.birthday = false;
        this.error.mobile = false;
        this.comfirmDisabled = true;
    },
    // 新增用戶api
    callApiCreateUser(){
        loadingStart();
        if(this.formAddMember.invite == ''){
            delete this.formAddMember.invite;
        }
        apiCreateUser(
            this.formAddMember,
        )
        .then(res=> {
            loadingFinished();
            this.modalClose();
            if(res.data.code == 0){
                this.$notify({
                    title: '新增資料',
                    message: '新增用戶成功',
                    type: 'success'
                });
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "新增用戶資料失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
    // 驗證必填欄位
    checkRequired(){
        if(this.formAddMember.mobile == ''){
            this.error.mobile = true;
        }
        if(this.formAddMember.password == '') {
            this.error.password = true;
        }
        if(this.formAddMember.nickname == '') {
            this.error.nickname = true;
        }
        if(this.formAddMember.birthday == '') {
            this.error.birthday = true;
        }
        if(this.formAddMember.email == '') {
            this.error.email = true;
        }
        if(this.formAddMember.id_card == ''){
            this.error.id_card = true;
        }

        // 驗證所有欄位
        if(this.error.email == false &&
            this.error.nickname == false &&
            this.error.password == false &&
            this.error.id_card == false &&
            this.error.mobile == false &&
            this.error.birthday == false){
            return true;
        }else{
            return false;
        }
    },
  },
}
</script>
<style scoped lang="scss">
.modal-add-member {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
    .form-radio-wrap {
        width: 100%;
    }
    &__form-input {
        label {
            white-space: nowrap;
        }
    }
}
</style>