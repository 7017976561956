<template>
    <el-aside class="sidebar">
    <!-- <el-aside class="sidebar" :class="{collapsed: sideBarIsCollapse}"> -->
        <el-menu
        :default-active="activeIndex"
        @open="handleOpen"
        @close="handleClose"
        background-color="#02152b"
        text-color="#fff"
        active-text-color="#1890ff">
            <!-- sidebar menu1 -->
            <el-submenu index="member">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>會員管理</span>
                </template>
                <router-link :to="{name: 'MemberList'}">
                    <el-menu-item index="memberList">會員列表</el-menu-item>
                </router-link>
            </el-submenu>
            <!-- sidebar menu2 -->
            <el-submenu index="2">
                <template slot="title">
                    <i class="el-icon-s-data"></i>
                    <span>報表管理</span>
                </template>
                <router-link :to="{name: 'ReferralDaliyList'}">
                    <el-menu-item index="referralDaliyList">用戶推薦時數日報表</el-menu-item>
                </router-link>
                <router-link :to="{name: 'ReferralMonthlyList'}">
                    <el-menu-item index="referralMonthlyList">用戶推薦時數月報表</el-menu-item>
                </router-link>
                <router-link :to="{name: 'ChatHourDaliyList'}">
                    <el-menu-item index="chatHourDaliyList">貴妃聊天時數日報表</el-menu-item>
                </router-link>
                <router-link :to="{name: 'ChatHourMonthlyList'}">
                    <el-menu-item index="chatHourMonthlyList">貴妃聊天時數月報表</el-menu-item>
                </router-link>
                <router-link :to="{name: 'SalaryMonthlyList'}">
                    <el-menu-item index="salaryMonthlyList">貴妃薪資月報表</el-menu-item>
                </router-link>
                <router-link :to="{name: 'ChatRankMonthlyList'}">
                    <el-menu-item index="chatRankMonthlyList">貴妃聊天時數月排行</el-menu-item>
                </router-link>
            </el-submenu>
            <!-- sidebar menu3 -->
            <el-submenu index="3">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>平台管理</span>
                </template>
                <router-link :to="{name: 'AnnouncementList'}">
                    <el-menu-item index="announcementList">會員公告</el-menu-item>
                </router-link>
            </el-submenu>

            <div class="version">v 1.0.16 {{appEnv}}</div>
        </el-menu>
    </el-aside>
</template>

<script>
export default {
    data () {
        return {
            activeIndex: "",
            appEnv: process.env.VUE_APP_ENV,
        }
    },
    props: {
        // sideBarIsCollapse: {
        //     type: Boolean,
        //     default: false
        // },
    },
    mounted() {
        this.activeIndex = window.location.href.split('/')[4];
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
    }
}
</script>

<style scoped lang="scss">
.el-aside.sidebar {
    transition: width .5s ease;
    position: fixed;
    height: 100%;
    .version {
        color: white;
        font-weight: 600;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
    .el-menu {
        height: calc(100% - 80px);
        // overflow: scroll;
        margin-top: 80px;
        .el-submenu {
            &__title {
                i {
                    color: white;
                }
            }
        }
    }
    .el-menu-item {
        text-align: left;
        background-color: $dark-blue !important;
        &:hover {
            background-color: $black-blue !important;
            // color: $black !important;
        }
    }
}
// .collapsed {
//     width: 36px !important;
// }
</style>