<template>
  <div class="chat-hour-daliy-list layout-grid">
  <!-- 篩選資料 -->
    <div class="filter">
      <div class="left">
        <div class="input-wrap">
          <label>日期：</label>
          <el-date-picker
            :editable="false"
            v-model="formFilter.date"
            type="date"
            :clearable="false"
            value-format="yyyy-MM-dd"
            ref="filterDate"
            placeholder="請輸入日期"
            :picker-options="pickerOptions"
            @focus="pickerFocus()">
          </el-date-picker>
        </div>
        <div class="filter-btn-row">
          <el-button type="primary" @click="filterSearch()">查詢</el-button>
          <el-button @click="filterReset()">重置</el-button>
        </div>
      </div>
    </div>
    <!-- 資料列表 -->
    <div class="data-list">
      <el-table
        :data="displayData">
        <el-table-column
          align="center"
          prop="seq"
          label="序號">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="貴妃ID">
          <template slot-scope="scope">
            <el-button type="text" @click="openDetailModal(scope.row)">{{ scope.row.user_id }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="暱稱">
        </el-table-column>
         <el-table-column
          prop="id_card"
          label="身分證字號">
        </el-table-column>
        <el-table-column
          align="center"
          label="聊天時數"
          :formatter="chatHourFormatter">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[50]"
        :page-size="formFilter.pageSize"
        layout="prev, pager, next, sizes"
        :total="formFilter.totalPage">
      </el-pagination>
    </div>
    <!-- 用戶詳細 modal -->
    <modal-member-detail :userId="memberDetailId" :isDialogVisible="memberDetailDialogVisible" v-on:isMemberDetailDialogVisible="changeMemberDetailDialogVisible" />
  </div> 
</template>

<script>
import { apiGetChatHourDaliyList } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'
import ModalMemberDetail from '@/components/memberList/modalMemberDetail'

export default {
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          var filterDate = new Date();
          filterDate.setMonth(filterDate.getMonth()-6);
          filterDate.setDate(1);
          filterDate.setDate(filterDate.getDate() - 1);
          // 除了當月，需看到前半年報表，最大選擇日期為昨日
          let yesterday = new Date().setDate(new Date().getDate() - 1);
          return time.getTime() >= yesterday || time.getTime() < filterDate.getTime();
        }
      }, 
      formFilter: {
        date: null,
        page: 1,
        pageSize: 50,
        totalPage: 0,
      },
      tableDataList: [],
      currentPage: 1,
      memberDetailId: null, 
      memberDetailDialogVisible: false,
    }
  },
  mounted(){
    // picker預設日期
    this.formFilter.date = this.defaultDate;

    this.callApiGetChatHourDaliyList();
  },
  components: {
    ModalMemberDetail,
  },
  methods: {
    pickerFocus(){
      // 重新render datepicker
      this.$refs.filterDate.mountPicker();
    },
    // 資料篩選 查詢 
    filterSearch(){
      this.currentPage = 1;
      // 日期 搜尋
      if(this.formFilter.date != ""){
        this.formFilter.page = 1;
        this.formFilter.totalPage = 1;
        this.callApiGetChatHourDaliyList();
      }
    },
    // 資料篩選 重置
    filterReset(){
      this.formFilter.date = this.defaultDate;
      this.formFilter.page = 1;
      this.currentPage = 1;
      this.callApiGetChatHourDaliyList();
    },
    // 聊天時數 欄位format顯示
    chatHourFormatter (row) {
      return row.duration + "小時"
    },
    // 開啟 用戶詳細 modal
    openDetailModal(row){
      setTimeout(()=>{ 
        this.memberDetailDialogVisible = true; 
      }, 100);
      this.memberDetailId = row.user_id;
    },
    // 子元件 傳回 用戶詳細modal狀態
    changeMemberDetailDialogVisible(isVisible){
      this.callApiGetChatHourDaliyList();
      this.memberDetailDialogVisible = isVisible;
    },
     // 取得用戶列表
    callApiGetChatHourDaliyList(){
      loadingStart();

      let param = "";
      // 頁碼/頁數
      param += `page=${this.formFilter.page}&size=${this.formFilter.pageSize}`;

      // 日期
      if(this.formFilter.date != ''){
        param += `&date=${this.formFilter.date}`;
      }

      apiGetChatHourDaliyList(
        param
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0){
          this.tableDataList = res.data.data;
          this.formFilter.totalPage = res.data.paging.total_page * res.data.paging.size;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    },
    // 切換每頁顯示的數量
    handleSizeChange(val) {
      this.currentPage = 1;
      this.formFilter.pageSize = val;
      this.formFilter.page = 1;
      this.callApiGetChatHourDaliyList();
    },
    // 切換當前頁數
    handleCurrentChange(val) {
      this.currentPage = val;
      this.formFilter.page = val;
      this.callApiGetChatHourDaliyList();
    },
  },
  computed: {
    displayData() {
      return this.tableDataList;
    },
    defaultDate() {
      // 昨天
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let month = yesterday.getMonth()+1;
      let date = yesterday.getDate();
      if(month < 10){
        month = "0" + month;
      }
      if(date < 10){
        date = "0" + date;
      }
      return `${yesterday.getFullYear()}-${month}-${date}`;
    }
  },
}
</script>

<style scoped lang="scss">
.chat-hour-daliy-list {
    
}
</style>
