<template>
  <div class="announcement-list layout-grid">
    <!-- 篩選資料 -->
    <div class="filter">
      <div class="left">
        <div class="select-wrap">
          <label>類型：</label>
          <el-select v-model="formFilter.category" placeholder="全部">
            <el-option
              v-for="category in filter.categoryList"
              :key="category.value"
              :label="category.label"
              :value="category.value">
            </el-option>
          </el-select>
        </div>
        <div class="filter-btn-row">
          <el-button type="primary" @click="filterSearch()">查詢</el-button>
          <el-button @click="filterReset()">重置</el-button>
        </div>
      </div>
      <div class="right">
        <div class="filter-btn-row">
          <el-button type="primary" @click="openAddNewsModal()">＋公告</el-button>
        </div>
      </div>
    </div>
    <!-- 資料列表 -->
    <div class="data-list">
      <el-table
        :data="displayData">
        <el-table-column
          prop="subject"
          label="標題">
        </el-table-column>
        <el-table-column
          label="類型"
          :formatter="categoryFormatter">
        </el-table-column>
        <el-table-column
          prop="create_at"
          label="上架時間">
        </el-table-column>
        <el-table-column
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button @click="openUpdateNewsModal(scope.row)" type="text">編輯</el-button>
          <el-button @click="openDeleteNewsModal(scope.row)" type="text" class="text-red">刪除</el-button>
        </template>
      </el-table-column>
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="formFilter.pageSize"
        layout="prev, pager, next, sizes"
        :total="formFilter.totalPage">
      </el-pagination>
    </div>
    <!-- 新增公告 modal -->
    <modal-add-announcement v-if="addNewsDialogVisible" :isDialogVisible="addNewsDialogVisible" v-on:isAddNewsDialogVisible="changeAddNewsDialogVisible"/>
    <!-- 更新公告 modal -->
    <modal-update-announcement :newsId="newsId" :isDialogVisible="updateNewsDialogVisible" v-on:isUpdateNewsDialogVisible="changeUpdateNewsDialogVisible"/>
    <!-- 刪除公告 modal -->
    <modal-delete-announcement :newsId="deleteNewsId" :isDialogVisible="deleteNewsDialogVisible" v-on:isDeleteNewsDialogVisible="changeDeleteNewsDialogVisible"/>
  </div>  
</template>

<script>
import { apiGetNewsList } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'
import ModalAddAnnouncement from '@/components/announcementList/modalAddAnnouncement'
import ModalUpdateAnnouncement from '@/components/announcementList/modalUpdateAnnouncement'
import ModalDeleteAnnouncement from '@/components/announcementList/modalDeleteAnnouncement'

export default {
  data () {
    return {
      addNewsDialogVisible: false,
      updateNewsDialogVisible: false,
      deleteNewsDialogVisible: false,
      newsId: null,
      deleteNewsId: null,
      formFilter: {
        category: '',
        page: 1,
        pageSize: 5,
        totalPage: 0,
      },
      filter: {
        categoryList: [
          {
            value: '',
            label: '全部'
          },
          {
            value: 1,
            label: '一般公告'
          },
        ],
      },
      tableDataList: [],
      currentPage: 1,
    }
  },
  mounted() {
    this.callApiGetNewsList();
  },
  watch: {
    // 篩選改變 清空page條件
    'formFilter.category': function() {
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
    },
  },
  computed: {
    displayData() {
      return this.tableDataList;
    }
  },
  methods: {
    // 切換當前頁數
    handleCurrentChange(val) {
      this.currentPage = val;
      this.formFilter.page = val;
      this.callApiGetNewsList()
    },
    // 切換每頁顯示的數量
    handleSizeChange(val) {
      this.currentPage = 1;
      this.formFilter.pageSize = val;
      this.formFilter.page = 1;
      this.callApiGetNewsList()
    },
    // 資料篩選 查詢 
    filterSearch(){
      this.currentPage = 1;
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
      // 角色/帳號狀態 篩選
      this.callApiGetNewsList();
    },
    // 資料篩選 重置
    filterReset(){
      this.formFilter.category = '';
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
      this.currentPage = 1;
      this.callApiGetNewsList();
    },
    // 取得 公告列表
    callApiGetNewsList() {
      loadingStart();

      let param = "";
      // 頁碼/頁數
      param += `page=${this.formFilter.page}&size=${this.formFilter.pageSize}`;

      // 類型
      if(this.formFilter.category != ''){
        param += `&category=${this.formFilter.category}`;
      }

      apiGetNewsList(
        param
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0){
          this.tableDataList = res.data.data;
          this.formFilter.totalPage = res.data.paging.total_page * res.data.paging.size;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    },
    // 類型 欄位format顯示
    categoryFormatter (row) {
      if(row.category == 1){
        return "一般"
      }
    },
    // 開啟 新增公告modal
    openAddNewsModal(){
      this.addNewsDialogVisible = true;
    },
    // 開啟 編輯公告modal
    openUpdateNewsModal(row){
      this.newsId = row.id;
      this.updateNewsDialogVisible = true;
    },
    // 開啟 刪除公告modal
    openDeleteNewsModal(row) {
      this.deleteNewsId = row.id;
      this.deleteNewsDialogVisible = true;
    },
    // 子元件 傳回 新增公告modal狀態
    changeAddNewsDialogVisible(isVisible){
      this.callApiGetNewsList();
      this.addNewsDialogVisible = isVisible;
    },
    // 子元件 傳回 編輯公告modal狀態
    changeUpdateNewsDialogVisible(isVisible){
      this.callApiGetNewsList();
      this.updateNewsDialogVisible = isVisible;
    },
    // 子元件 傳回 刪除公告modal狀態
    changeDeleteNewsDialogVisible(isVisible){
      this.callApiGetNewsList();
      this.deleteNewsDialogVisible = isVisible;
    },
  },
  components: {
    ModalAddAnnouncement,
    ModalUpdateAnnouncement,
    ModalDeleteAnnouncement,
  },
}
</script>

<style scoped lang="scss">
.announcement-list {
    
}
</style>
