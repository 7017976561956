import Vue from 'vue';
import axios from 'axios';
import store from "../store";
// 共用function 
import { getToken } from '@/function';

/**
 * axios
 */
const webTokenRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});
// request攔截器
webTokenRequest.interceptors.request.use(function (config) {
  config.headers.common = {'Token': getToken()}

  return config
}, function (error) {
  console.log("error:: ", error);
  return Promise.reject(error)
})

// Response攔截器
webTokenRequest.interceptors.response.use(
  function (response) {
    console.log("%c" + response.config.url, "color:green;");
    console.log(response);
    // console.info(response.config.url + "::", response);
    return response;
  },
  function (error) {
    if (error.response){
      console.log("%c" + error.response.config.url, "color:red;");
      switch (error.response.status) {
        case 400:
          if(error.response.data.code == 9005){
            // 顯示登出提醒
            store.dispatch('actionSetErrorModal400Display', true);
          }else{
            console.log("發生錯誤: ", error.response.msg);
          }
          break
        case 404:
          Vue.prototype.$notify.error({
            title: "404",
            message: "你要找的頁面不存在"
          });
          break
        case 500:
          Vue.prototype.$notify.error({
            title: "500",
            message: "程式發生錯誤，請聯絡管理員"
          });
          break
        default:
          Vue.prototype.$notify.error({
            title: "系統發生非預期錯誤，請聯絡管理員",
            message: error.message
          });
      }
      console.log(error.message);
    } 
    if (!window.navigator.onLine) {
      Vue.prototype.$notify.error({
        title: "沒有網路連線",
        message: "請重新連線後，重整網頁"
      });
      return;
    }
    return Promise.reject(error.response);
  }
);

// 多媒體API傳送格式 header
let imgConfig = {
  headers: {
      'Content-Type': 'multipart/form-data'
  }
}

/**
 * api
 */
// 登入
export const apiUserLogin = data => webTokenRequest.post('/manager/login', data); 
// 登出
export const apiUserLogout = () => webTokenRequest.post('/manager/logout');

// === 會員管理 ===
// 新增 用戶
export const apiCreateUser = (data) => webTokenRequest.post('/manager/user', data); 
// 更新 用戶指定欄位:userId單筆
export const apiUpdateUser = (data, userId) => webTokenRequest.patch(`/manager/user/${userId}`, data); 
// 刪除 用戶相簿照片:userId單筆
export const apiDeleteUserAlbumImgById = (albumId, userId) => webTokenRequest.delete(`/manager/user/${userId}/album/${albumId}`); 
// 更新 用戶相簿照片:userId單筆
export const apiUpdateUserAlbum = (data, userId) => webTokenRequest.post(`/manager/user/${userId}/album`, data, imgConfig); 
// 更新 用戶頭貼:userId單筆
export const apiUpdateUserPhote = (data, userId) => webTokenRequest.put(`/manager/user/${userId}/avatar`, data, imgConfig); 
// 取得 用戶詳細:userId單筆
export const apiGetUserDetail = (userId) => webTokenRequest.get(`/manager/user/${userId}/detail`); 
// 更新 帳戶狀態:userId單筆
export const apiUpdateUserStatus = (data, userId) => webTokenRequest.put(`/manager/user/${userId}/status`, data);
// 取得 用戶列表
export const apiGetUserList = (data) => webTokenRequest.get(`/manager/users/brief?${data}`); 
// 取得 用戶列表:userId搜尋
export const apiGetUserListByUserId = (userId) => webTokenRequest.get(`/manager/user/${userId}/brief`); 
// 更新 用戶密碼:userId單筆
export const apiUpdateUserPwd = (data, userId) => webTokenRequest.put(`/manager/user/${userId}/password`, data); 

// 取得 用戶儲值紀錄:userId單筆
export const apiGetUserOrders = (data, userId) => webTokenRequest.get(`/manager/user/${userId}/orders?${data}`); 
// 取得 用戶消費紀錄:userId單筆
export const apiGetUserReceipts = (data, userId) => webTokenRequest.get(`/manager/user/${userId}/chat_receipts?${data}`); 

// === 報表管理 ===
// 取得 用戶推薦時數日報表
export const apiGetReferralDaliyList = (data) => webTokenRequest.get(`/report/user/referral/daliy?${data}`); 
// 取得 用戶推薦時數月報表 
export const apiGetReferralMonthlyList = (data) => webTokenRequest.get(`/report/user/referral/monthly?${data}`); 
// 取得 貴妃時數日報表 
export const apiGetChatHourDaliyList = (data) => webTokenRequest.get(`/report/girl/chat/daliy?${data}`); 
// 取得 貴妃時數月報表 
export const apiGetChatHourMonthlyList = (data) => webTokenRequest.get(`/report/girl/chat/monthly?${data}`); 
// 取得 貴妃薪資月報表 
export const apiGetSalaryMonthlyList = (data) => webTokenRequest.get(`/report/girl/salary/monthly?${data}`); 
// 取得 貴妃聊天時數月排行
export const apiGetChatRankMonthlyList = (data) => webTokenRequest.get(`/report/girl/chat/rank/monthly?${data}`); 

// 取得 用戶推薦總紅利數
export const apiGetReferralBonusList = (data) => webTokenRequest.get(`/report/user/referral/bonus?${data}`); 
// 取得 貴妃聊天總時數
export const apiGetChatdurationList = (data) => webTokenRequest.get(`/report/girl/chat/duration?${data}`); 
// 取得 貴妃薪水加總
export const apiGetSalaryTotalList = (data) => webTokenRequest.get(`/report/girl/salary/total?${data}`); 

// === 平台管理 ===
// 取得 公告列表
export const apiGetNewsList = (data) => webTokenRequest.get(`/news?${data}`); 
// 取得 公告詳細:newsId單筆
export const apiGetNewsDetail = (newsId) => webTokenRequest.get(`/news/${newsId}`); 
// 新增 公告
export const apiCreateNews = (data) => webTokenRequest.post('/news', data); 
// 更新 公告:newsId單筆
export const apiUpdateNews = (data, newsId) => webTokenRequest.patch(`/news/${newsId}`, data); 
// 刪除 公告:newsId單筆
export const apiDeleteNews = (newsId) => webTokenRequest.delete(`/news/${newsId}`);
