// 引入 mutations_type （引用同一個 key）
import * as types from './mutations_type.js'

// 儲存token
export const actionSetToken = ({ commit}, token) => {
  commit(types.TOKEN, {token});
}
// 儲存erro 400modal顯示狀態
export const actionSetErrorModal400Display = ({ commit}, status) => {
  commit(types.ERRORMODAL400, {status});
}