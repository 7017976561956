import { Loading } from 'element-ui'

// access token
const TokenKey = 'token'
const UserInfoKey = 'userInfo'

// 圖片主機路徑
const UserAlbumUrl = process.env.VUE_APP_USER_ALBUM_URL;
const UserAvatarUrl = process.env.VUE_APP_USER_AVATER_URL;

/** 
 * 取得 token 
 */
export function getToken() {
    return localStorage.getItem(TokenKey)
}
/** 
 * 設定 token
 * @param   {string}    token 
 */
export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
}
/** 
 * 移除 token
 */
export function removeToken() {
    return localStorage.removeItem(TokenKey)
}

/** 
 * 取得 userInfo 
 */
export function getUserInfo() {
    return localStorage.getItem(UserInfoKey)
}
/** 
 * 設定 userInfo
 * @param   {string}    userInfo 
 */
export function setUserInfo(userInfo) {
    return localStorage.setItem(UserInfoKey, userInfo)
}
/** 
 * 移除 userInfo
 */
export function removeUserInfo() {
    return localStorage.removeItem(UserInfoKey)
}
/** 
 * loading載入中
 */
 // loading
 let loading = "";
 export function loadingStart() {
    loading = Loading.service({
        lock: true,
        text: '載入中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
}
export function loadingFinished() {
    loading.close();
}
/** 
 * 用戶 相簿 url
 */
export function userAlbumUrl(image, userId) {
    console.log(userId)
    return UserAlbumUrl + image;
}
/** 
 * 用戶 大頭貼 url
 */
 export function userAvatarUrl(image, userId) {
    console.log(userId)
    return UserAvatarUrl + image;
}