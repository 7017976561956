<template>
  <div class="referral-monthly-list layout-grid">
    <!-- 篩選資料 -->
    <div class="filter">
      <div class="left">
        <div class="input-wrap">
          <label>月份：</label>
          <el-date-picker
            :editable="false"
            v-model="formFilter.date"
            type="month"
            :clearable="false"
            value-format="yyyy-MM"
            ref="filterDate"
            placeholder="請輸入月份"
            :picker-options="pickerOptions"
            @focus="pickerFocus()">
          </el-date-picker>
        </div>
        <div class="filter-btn-row">
          <el-button type="primary" @click="filterSearch()">查詢</el-button>
          <el-button @click="filterReset()">重置</el-button>
        </div>
      </div>
      <div class="right">
        <div class="filter-text filter-text--end">藍鑽加總：{{total}}個<span class="text-gray divide">|</span>結算區間：{{startDate}} ~ {{endDate}}</div>
      </div>
    </div>
    <!-- 資料列表 -->
    <div class="data-list">
      <el-table
        :data="displayData">
        <el-table-column
          align="center"
          prop="seq"
          label="序號">
        </el-table-column>
        <el-table-column
          prop="user_id"
          label="用戶ID">
          <template slot-scope="scope">
            <el-button type="text" @click="openDetailModal(scope.row)">{{ scope.row.user_id }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="性別"
          :formatter="sexFormatter">
        </el-table-column>
         <el-table-column
          prop="id_card"
          label="身分證字號">
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="暱稱">
        </el-table-column>
        <el-table-column
          align="center"
          label="推薦貴妃時數"
          :formatter="girlHourFormatter">
        </el-table-column>
        <el-table-column
          align="center"
          label="推薦玩家時數"
          :formatter="memberHourFormatter">
        </el-table-column>
        <el-table-column
          align="center"
          label="總藍鑽數"
          :formatter="bonusFormatter">
        </el-table-column>
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[50]"
        :page-size="formFilter.pageSize"
        layout="prev, pager, next, sizes"
        :total="formFilter.totalPage">
      </el-pagination>
    </div>
    <!-- 用戶詳細 modal -->
    <modal-member-detail :userId="memberDetailId" :isDialogVisible="memberDetailDialogVisible" v-on:isMemberDetailDialogVisible="changeMemberDetailDialogVisible" />
  </div>  
</template>

<script>
import { apiGetReferralMonthlyList, apiGetReferralBonusList } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'
import ModalMemberDetail from '@/components/memberList/modalMemberDetail'

export default {
  data () {
    return {
      pickerOptions: {
        disabledDate(time) {
          var filterDate = new Date();
          filterDate.setMonth(filterDate.getMonth()-6);
          filterDate.setDate(1);
          filterDate.setDate(filterDate.getDate() - 1);
          // 除了當月，需看到前半年報表，最大選擇日期為昨日
          let yesterday = new Date().setDate(new Date().getDate() - 1);
          return time.getTime() >= yesterday || time.getTime() < filterDate.getTime();
        }
      }, 
      formFilter: {
        date: null,
        page: 1,
        pageSize: 50,
        totalPage: 0,
      },
      total: null, 
      startDate: null,  
      endDate: null,
      tableDataList: [],
      currentPage: 1,
      memberDetailId: null, 
      memberDetailDialogVisible: false,
    }
  },
  mounted(){
    // picker預設日期
    this.formFilter.date = this.defaultDate;

    this.callApi();
  },
  components: {
    ModalMemberDetail,
  },
  methods: {
    pickerFocus(){
      // 重新render datepicker
      // this.$refs.filterDate.mountPicker();
    },
    // 資料篩選 查詢 
    filterSearch(){
      this.currentPage = 1;
      // 日期 搜尋
      if(this.formFilter.date != ""){
        this.formFilter.page = 1;
        this.formFilter.totalPage = 1;
        this.callApi();
      }
    },
    // 資料篩選 重置
    filterReset(){
      this.formFilter.date = this.defaultDate;
      this.formFilter.page = 1;
      this.currentPage = 1;
      this.callApi();
    },
    // 性別 欄位format顯示
    sexFormatter (row) {
      let sex = row.sex
      if(sex == "f"){
          sex = "女性";
      }else if(sex == "m"){
          sex = "男性";
      }
      return sex;
    },
    // 推薦貴妃時數 欄位format顯示
    girlHourFormatter (row) {
      return row.girl_duration + "小時"
    },
    // 推薦玩家時數 欄位format顯示
    memberHourFormatter (row) {
      return row.member_duration + "小時"
    },
    // 總藍鑽數 欄位format顯示
    bonusFormatter (row) {
      return row.bonus + "個"
    },
    // 開啟 用戶詳細 modal
    openDetailModal(row){
      setTimeout(()=>{ 
        this.memberDetailDialogVisible = true; 
      }, 100);
      this.memberDetailId = row.user_id;
    },
    // 子元件 傳回 用戶詳細modal狀態
    changeMemberDetailDialogVisible(isVisible){
      this.callApi();
      this.memberDetailDialogVisible = isVisible;
    },
    callApi(){
      this.callApiGetReferralMonthlyList();
      this.callApiGetReferralBonusList()
    },
    // 取得 用戶推薦時數月報表
    callApiGetReferralMonthlyList(){
      loadingStart();

      let param = "";
      // 頁碼/頁數
      param += `page=${this.formFilter.page}&size=${this.formFilter.pageSize}`;

      // 日期
      let year = this.formFilter.date.split('-')[0]
      let month = this.formFilter.date.split('-')[1]
      param += `&year=${year}&month=${month}`;

      apiGetReferralMonthlyList(
        param
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0){
          this.tableDataList = res.data.data;
          this.formFilter.totalPage = res.data.paging.total_page * res.data.paging.size;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    },
     // 取得 用戶推薦總紅利數
    callApiGetReferralBonusList(){
      loadingStart();

      let param = "";
      // 日期
      let year = this.formFilter.date.split('-')[0]
      let month = this.formFilter.date.split('-')[1]
      param += `year=${year}&month=${month}`;

      apiGetReferralBonusList(
        param
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0){
          this.total = res.data.data.bonus;
          this.startDate = res.data.data.start_date;
          this.endDate = res.data.data.end_date;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    },
    // 切換每頁顯示的數量
    handleSizeChange(val) {
      this.currentPage = 1;
      this.formFilter.pageSize = val;
      this.formFilter.page = 1;
      this.callApi();
    },
    // 切換當前頁數
    handleCurrentChange(val) {
      this.currentPage = val;
      this.formFilter.page = val;
      this.callApi();
    },
  },
  computed: {
    displayData() {
      return this.tableDataList;
    },
    defaultDate() {
      // 昨天
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let month = yesterday.getMonth()+1;
      if(month < 10){
        month = "0" + month;
      }
      return `${yesterday.getFullYear()}-${month}`;
    }
  },
}
</script>

<style scoped lang="scss">
.referral-monthly-list {
    
}
</style>
