<template>
    <div class="modal-remove-img">
        <el-dialog
            :key="'刪除相簿圖片'"
            title="刪除相簿圖片"
            :visible.sync="isDialogVisible"
            width="30%"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-remove-img__form form-wrap">
                <div class="modal-remove-img__form-input form-input form-textarea">
                    確定要刪除此相簿圖片？
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="removeImg()">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
        formLabelWidth: "50",
        comfirmDisabled: false,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    },
    userId: {
        type: Number,
        default: null,
    },
    oldValue: {
        type: Number,
        default: null,
    }
  },
    computed: {
        status:  {
            get: function () {
                return this.oldValue
            },
            set: function (newValue) {
                this.oldValue = newValue
            }
        }
    },
    watch: { 
    },
  methods: {
    // 刪除
    removeImg(){
        this.$emit("isRemoveImgDialogVisible", {visible: false, change: true});
    },
    // 關閉 modal
    modalClose() {
        this.$emit("isRemoveImgDialogVisible", {visible: false, change: false});
    },
  },
}
</script>
<style scoped lang="scss">
.modal-remove-img {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>