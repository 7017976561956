import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '../function'

import Layout from '../components/layout/Layout.vue'
import Login from '../views/Login.vue'
// 會員管理
import MemberList from '../views/MemberList.vue'
// 報表管理
import ReferralDaliyList from '../views/ReferralDaliyList.vue'
import ReferralMonthlyList from '../views/ReferralMonthlyList.vue'
import ChatHourDaliyList from '../views/ChatHourDaliyList.vue'
import ChatHourMonthlyList from '../views/ChatHourMonthlyList.vue'
import SalaryMonthlyList from '../views/SalaryMonthlyList.vue'
import ChatRankMonthlyList from '../views/ChatRankMonthlyList.vue'
// 平台管理
import AnnouncementList from '../views/AnnouncementList.vue'

Vue.use(VueRouter)

const routes = [
  // 預設路徑：登入
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  // 登入
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // 會員管理
  {
    path: '/memberMgt',
    name: 'MemberMgt',
    component: Layout,
    children: [
      {
        // 預設路徑=>會員列表
        path: '/',
        redirect: '/memberMgt/memberList',
      },
      {
        // 會員列表：登入後首頁
        path: 'memberList',
        name: 'MemberList',
        meta: {
          requiresAuth: true,
        },
        component: MemberList,
      },
    ]
  },
  // 報表管理
  {
    path: '/reportMgt',
    name: 'ReportMgt',
    component: Layout,
    children: [
      {
        // 預設路徑=>用戶推薦時數日報表
        path: '/',
        redirect: '/reportMgt/referralDaliyList',
      },
      {
        // 用戶推薦時數日報表
        path: 'referralDaliyList',
        name: 'ReferralDaliyList',
        component: ReferralDaliyList,
      },
      {
        // 用戶推薦時數月報表
        path: 'referralMonthlyList',
        name: 'ReferralMonthlyList',
        component: ReferralMonthlyList,
      },
      {
        // 貴妃聊天時數日報表
        path: 'chatHourDaliyList',
        name: 'ChatHourDaliyList',
        component: ChatHourDaliyList,
      },
      {
        // 貴妃聊天時數月報表
        path: 'chatHourMonthlyList',
        name: 'ChatHourMonthlyList',
        component: ChatHourMonthlyList,
      },
      {
        // 貴妃薪資月報表
        path: 'salaryMonthlyList',
        name: 'SalaryMonthlyList',
        component: SalaryMonthlyList,
      },
      {
        // 貴妃聊天時數月排行
        path: 'chatRankMonthlyList',
        name: 'ChatRankMonthlyList',
        component: ChatRankMonthlyList,
      },
    ]
  },
  // 平台管理
  {
    path: '/systemMgt',
    name: 'SystemMgt',
    component: Layout,
    meta: {
        requiresAuth: true,
    },
    children: [
      {
        // 預設路徑=>會員公告
        path: '/',
        redirect: '/systemMgt/announcementList',
      },
      {
        // 會員公告
        path: 'announcementList',
        name: 'AnnouncementList',
        component: AnnouncementList,
      },
    ]
  },

  // 不符合router表預設轉址到登入:放最後
  { 
    path: '/*', 
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由檢查
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    // 需要token才能進入
    if (getToken()) {
      // 有登入
      next()
    } else {
      // 導回登入頁面
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    // 不需要token
    next()
  }
});

export default router