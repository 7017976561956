<!-- 整個專案的進入點 -->
<template>
  <div id="app">
    <router-view></router-view>
    <error-modal-400 :isDialogVisible="error400DialogVisible"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorModal400 from '@/components/error/errorModal400'

export default {
  data () {
    return {
    }
  },
  components: {
    ErrorModal400
  },
  methods: {
    
  },
  computed: {
    ...mapGetters({
      error400DialogVisible: 'getErrorModal400Display',
    }),
  },
}
</script>

<style lang="scss">
html, body {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: $black;
}
* {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $black;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: $white;
}
a:hover, a:focus {
  color: $white;
}

.el-submenu {
  &__title {
    text-align: left;
  }
}

</style>
