<template>
    <div class="modal-member-detail">
        <el-dialog
            :key="'用戶詳細'"
            title="用戶詳細"
            :visible.sync="isDialogVisible"
            width="60%"
            style="margin-top: -10vh;"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <!-- 基本資訊 -->
                <el-tab-pane label="基本資訊" name="info">
                    <ul class="form-table">
                        <li class="form-table__row">
                            <div class="form-table__title">用戶ID</div>
                            <div class="form-table__content">{{userId}}</div>
                            <div class="form-table__title">角色</div>
                            <div class="form-table__content">{{formatRole}}</div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">暱稱</div>
                            <div class="form-table__content">
                                {{formMemberDetail.info.nickname}}
                                <el-popover
                                    ref="nickname"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isNickNameVisible">
                                    <div class="popover__title">編輯暱稱</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isNickNameVisible','nickname', formMemberDetail.info.nickname)"><i class="el-icon-edit" id="nickname"></i></el-button>
                                    <div class="popover__content">
                                       <el-input placeholder="請輸入暱稱" v-model="formUpdate.nickname"></el-input>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isNickNameVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isNickNameVisible', 'nickname')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                            <div class="form-table__title">介紹</div>
                            <div class="form-table__content">
                                <label>{{formMemberDetail.info.intro}}</label>
                                <el-button type="text" @click="openUpdateIntroModal()"><i class="el-icon-edit"></i></el-button>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">性別</div>
                            <div class="form-table__content">
                                {{formatSex}}
                                <el-popover
                                    ref="sex"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isSexVisible">
                                    <div class="popover__title">編輯性別</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isSexVisible','sex', formMemberDetail.info.sex)"><i class="el-icon-edit" id="sex"></i></el-button>
                                    <div class="popover__content">
                                        <el-select v-model="formUpdate.sex" placeholder="請選擇性別">
                                            <el-option :label="'女性'" :value="'f'" :key="'f'"></el-option>
                                            <el-option :label="'男性'" :value="'m'" :key="'m'"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isSexVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isSexVisible','sex')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                            <div class="form-table__title">生日</div>
                            <div class="form-table__content">
                                {{formMemberDetail.info.birthday}}
                                <el-popover
                                    ref="birthday"
                                    :appendToBody="false"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isBirthdayVisible">
                                    <div class="popover__title">編輯生日</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isBirthdayVisible','birthday', formMemberDetail.info.birthday)"><i class="el-icon-edit" id="birthday"></i></el-button>
                                    <div class="popover__content">
                                        <el-date-picker
                                            :editable="false"
                                            v-model="formUpdate.birthday"
                                            type="date"
                                            :clearable="false"
                                            value-format="yyyy-MM-dd"
                                            ref="birthdayPicker"
                                            placeholder="請輸入生日" 
                                            :picker-options="pickerOptions"
                                            @focus="pickerFocus()">
                                        </el-date-picker>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isBirthdayVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isBirthdayVisible', 'birthday')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">信箱</div>
                            <div class="form-table__content">
                                <label>{{formMemberDetail.info.email}}</label>
                                <el-popover
                                    ref="email"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isEmailVisible">
                                    <div class="popover__title">編輯信箱</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isEmailVisible','email', formMemberDetail.info.email)"><i class="el-icon-edit" id="email"></i></el-button>
                                    <div class="form-wrap">
                                        <div class="popover__content form-input" :class="{'error': error.email}">
                                            <el-input type="text" placeholder="請輸入信箱" v-model="formUpdate.email"></el-input>
                                            <div class="error-msg">需符合信箱格式</div>
                                        </div>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isEmailVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isEmailVisible', 'email')" :disabled="comfirmDisabled.email">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                            <div class="form-table__title">地區</div>
                            <div class="form-table__content">
                                {{formMemberDetail.info.area}}
                                <el-popover
                                    ref="area"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isAreaVisible">
                                    <div class="popover__title">編輯地區</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isAreaVisible','area', formMemberDetail.info.area)"><i class="el-icon-edit" id="area"></i></el-button>
                                    <div class="popover__content">
                                        <el-select v-model="formUpdate.area" placeholder="請輸入地區">
                                            <el-option
                                                v-for="area in areaList"
                                                :key="area"
                                                :label="area"
                                                :value="area">
                                            </el-option>
                                        </el-select>

                                       <!-- <el-input placeholder="請輸入地區" v-model="formUpdate.area"></el-input> -->
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isAreaVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isAreaVisible', 'area')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">身分證字號</div>
                            <div class="form-table__content"><label>{{formMemberDetail.info.id_card}}</label></div>
                            <div class="form-table__title">身高</div>
                            <div class="form-table__content">
                                {{formMemberDetail.info.height}}
                                <el-popover
                                    ref="height"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isHeightVisible">
                                        <div class="popover__title">編輯身高</div>
                                        <el-button type="text" slot="reference" @click="openPopOver('isHeightVisible','height', formMemberDetail.info.height)"><i class="el-icon-edit" id="height"></i></el-button>
                                        <div class="form-wrap">
                                            <div class="popover__content form-input" :class="{'error': error.height}">
                                                <el-input placeholder="請輸入身高" v-model="formUpdate.height"></el-input>
                                                <div class="error-msg">僅能輸入數字且最大值為230</div>
                                            </div>
                                        </div>
                                        <div class="popover__btn-row">
                                            <el-button type="default" size="mini" @click="closePopOver('isHeightVisible')">取 消</el-button>
                                            <el-button type="primary" size="mini" @click="updateValue('isHeightVisible','height')" :disabled="comfirmDisabled.height">確 認</el-button>
                                        </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">帳號類型</div>
                            <div class="form-table__content">{{formatAccountType}}</div>
                            <div class="form-table__title">體重</div>
                            <div class="form-table__content">
                                {{formMemberDetail.info.weight}}
                                <el-popover
                                    ref="weight"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isWeightVisible">
                                    <div class="popover__title">編輯體重</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isWeightVisible','weight', formMemberDetail.info.weight)"><i class="el-icon-edit" id="weight"></i></el-button>
                                    <div class="form-wrap">
                                        <div class="popover__content form-input" :class="{'error': error.weight}">
                                            <el-input placeholder="請輸入體重" v-model="formUpdate.weight"></el-input>
                                            <div class="error-msg">僅能輸入數字且最大值為250</div>
                                        </div>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isWeightVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isWeightVisible','weight')" :disabled="comfirmDisabled.weight">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">帳號</div>
                            <div class="form-table__content"><label>{{formMemberDetail.auth.identifier}}</label></div>
                            <div class="form-table__title">喜好</div>
                            <div class="form-table__content">
                                <label>{{formMemberDetail.info.favorite}}</label>
                                <el-button type="text" @click="openUpdateFavoriteModal()"><i class="el-icon-edit"></i></el-button>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">帳號狀態</div>
                            <div class="form-table__content modal-member-detail__overlay-wrap">
                                <el-switch
                                    v-model="accountStatus"
                                    active-text="啟用"
                                    inactive-text="禁用">
                                </el-switch>
                                <div class="modal-member-detail__overlay" @click="openUpdateStatusModal()"></div>
                            </div>
                            <div class="form-table__title">是否抽煙</div>
                            <div class="form-table__content">
                                {{formatSmoke}}
                                <el-popover
                                    ref="smoke"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isSmokeVisible">
                                    <div class="popover__title">編輯是否抽煙</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isSmokeVisible','smoke', formMemberDetail.info.smoke)"><i class="el-icon-edit" id="smoke"></i></el-button>
                                    <div class="popover__content">
                                        <el-select v-model="formUpdate.smoke" placeholder="請選擇是否抽煙">
                                            <el-option :label="'否'" :value="0" :key="0"></el-option>
                                            <el-option :label="'是'" :value="1" :key="1"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isSmokeVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isSmokeVisible','smoke')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">個人推薦碼</div>
                            <div class="form-table__content">{{formMemberDetail.info.invite_code}}</div>
                            <div class="form-table__title">是否喝酒</div>
                            <div class="form-table__content">
                                {{formatDrink}}
                                <el-popover
                                    ref="drink"
                                    class="popover"
                                    placement="top"
                                    width="200"
                                    trigger="manual"
                                    v-model="popover.isDrinkVisible">
                                    <div class="popover__title">編輯是否喝酒</div>
                                    <el-button type="text" slot="reference" @click="openPopOver('isDrinkVisible','drink', formMemberDetail.info.drink)"><i class="el-icon-edit" id="drink"></i></el-button>
                                    <div class="popover__content">
                                        <el-select v-model="formUpdate.drink" placeholder="請選擇是否喝酒">
                                            <el-option :label="'否'" :value="0" :key="0"></el-option>
                                            <el-option :label="'是'" :value="1" :key="1"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="popover__btn-row">
                                        <el-button type="default" size="mini" @click="closePopOver('isDrinkVisible')">取 消</el-button>
                                        <el-button type="primary" size="mini" @click="updateValue('isDrinkVisible','drink')">確 認</el-button>
                                    </div>
                                </el-popover>
                            </div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">推薦人ID</div>
                            <div class="form-table__content">{{(formMemberDetail.info.invite_user_id == null || formMemberDetail.info.invite_user_id == 0)?'-':formMemberDetail.info.invite_user_id}}</div>
                            <div class="form-table__title">註冊日期</div>
                            <div class="form-table__content">{{formMemberDetail.create_at}}</div>
                        </li>
                        <li class="form-table__row">
                            <div class="form-table__title">業務模式</div>
                            <div class="form-table__content modal-member-detail__overlay-wrap">
                                <el-switch
                                    v-model="salesModeStatus"
                                    active-text="啟用"
                                    inactive-text="禁用">
                                </el-switch>
                                <div class="modal-member-detail__overlay" @click="openUpdateSalesModeModal()"></div>
                            </div>
                            <div class="form-table__title">預計下線時間</div>
                            <div class="form-table__content">{{formMemberDetail.info.est_offline}}</div>
                        </li>
                    </ul>
                </el-tab-pane>
                <!-- 用戶相簿 -->
                <el-tab-pane label="用戶相簿" name="photo">
                    <div class="modal-member-detail__full-img" :class="{'modal-member-detail__full-img--active': showfullImg}" @click="showfullImg = false">
                        <div class="modal-member-detail__full-img-close">
                            <i class="el-icon-close"></i>
                        </div>
                        <img :src="fullImgSrc"/>
                    </div>

                    <div class="modal-member-detail__propic">
                        <div class="modal-member-detail__propic-title">大頭貼</div>
                        <div class="modal-member-detail__propic-inner">
                            <div class="modal-member-detail__propic-file">
                                <div class="modal-member-detail__propic-file-img-wrap" v-show="imgPropic">
                                    <img ref="avatarImg" class="avatar modal-member-detail__propic-file-img" :class="{'avatar modal-member-detail__propic-file-img--v':avatarV}" :src="imgPropic" @click="fullSizePic(imgPropic)">
                                </div>
                                <el-upload
                                    ref="propicFile"
                                    action=""
                                    :show-file-list="false"
                                    :auto-upload="false"
                                    :on-change="propicChange">
                                    <div v-if="imgPropic">
                                        <div class="modal-member-detail__propic-file-upload">
                                            <i class="el-icon-upload2"></i>
                                            更換大頭貼
                                        </div>
                                    </div>
                                    <div v-else>
                                        <i class="el-icon-plus uploader-icon"></i>
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                    </div>
                    
                    <div class="modal-member-detail__photo-album">
                        <div class="modal-member-detail__photo-album-title">相簿</div>
                        <div class="modal-member-detail__photo-album-file">
                            <el-upload
                                ref="albumFile"
                                action=""
                                :show-file-list="false"
                                :auto-upload="false"
                                :on-change="albumChange">
                               <div class="modal-member-detail__photo-album-file-upload">
                                    <div>
                                        <div><i class="el-icon-plus uploader-icon"></i></div>
                                        <div>上傳</div>
                                    </div>
                                </div>
                            </el-upload>
                            <div v-if="imgAlbumList">
                                <div class="modal-member-detail__album-file-upload">
                                    <div class="modal-member-detail__album-file-img-inner" v-for="img in imgAlbumList" :key="img.id" @mouseenter="imgMouseEnter(img.id)" @mouseleave="imgMouseLeave(img.id)">
                                        <div class="modal-member-detail__album-file-remove" @click.capture="fullSizePic(imageAlbumUrl(img.image,userId))">
                                            <i class="el-icon-close" @click="removeImg(img.id)"></i>
                                        </div>
                                        <img :src="imageAlbumUrl(img.image,userId)" class="modal-member-detail__album-file-img"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <!-- 儲值記錄 -->
                <el-tab-pane label="儲值紀錄" name="store">
                    <MemberStoreList :userId="userId" v-if="load.store"/>
                </el-tab-pane>
                <!-- 消費記錄 -->
                <el-tab-pane label="消費紀錄" name="consume">
                    <MemberConsumeList :userId="userId" v-if="load.consume"/>
                </el-tab-pane>
            </el-tabs>
            <!-- 修改介紹 modal -->
            <modal-update-intro :userId="userId" :oldValue="formMemberDetail.info.intro" :isDialogVisible="updateIntroDialogVisible" v-on:isUpdateIntroDialogVisible="changeUpdateIntroDialogVisible"/>
            <!-- 修改喜好 modal -->
            <modal-update-favorite :userId="userId" :oldValue="formMemberDetail.info.favorite" :isDialogVisible="updateFavoriteDialogVisible" v-on:isUpdateFavoriteDialogVisible="changeUpdateFavoriteDialogVisible"/>
            <!-- 修改帳戶狀態 modal -->
            <modal-update-status :userId="userId" :oldValue="formMemberDetail.status" :isDialogVisible="updateStatusDialogVisible" v-on:isUpdateStatusDialogVisible="changeUpdateStatusDialogVisible"/>
            <!-- 刪除相簿圖片 modal -->
            <modal-remove-img :userId="userId" :oldValue="formMemberDetail.status" :isDialogVisible="removeImgDialogVisible" v-on:isRemoveImgDialogVisible="changeRemoveImgDialogVisible"/>
            <!-- 修改業務模式 modal -->
            <modal-update-sales-mode :userId="userId" :oldValue="formMemberDetail.sales_mode" :isDialogVisible="updateSalesModeDialogVisible" v-on:isUpdateSalesModeDialogVisible="changeUpdateSalesModeDialogVisible"/>
        </el-dialog>
    </div>
</template>
<script>
import { apiGetUserDetail, apiUpdateUser, apiUpdateUserPhote, apiDeleteUserAlbumImgById, apiUpdateUserAlbum, apiUpdateUserStatus } from "@/api/api"; 
import { loadingStart, loadingFinished, userAvatarUrl, userAlbumUrl } from '@/function'
import ModalUpdateIntro from '@/components/memberList/modalUpdateIntro'
import ModalUpdateFavorite from '@/components/memberList/modalUpdateFavorite'
import ModalUpdateStatus from '@/components/memberList/modalUpdateStatus'
import ModalUpdateSalesMode from '@/components/memberList/modalUpdateSalesMode'
import ModalRemoveImg from '@/components/memberList/modalRemoveImg'
import MemberStoreList from '@/components/memberDetail/memberStoreList';
import MemberConsumeList from '@/components/memberDetail/memberConsumeList';

export default {
    data () {
        return {
            pickerOptions: {
                disabledDate(time) {
                    // 不能選：大於今天的日期
                    return time.getTime() > Date.now() 
                }
            },  
            load: {
                store: false,
                consume: false,
            },
            renderDefault: false,
            updateIntroDialogVisible: false,
            updateFavoriteDialogVisible: false,
            updateStatusDialogVisible: false,
            updateSalesModeDialogVisible: false,
            removeImgDialogVisible: false,
            deleteId: null,
            formLabelWidth: "50",
            activeName: 'info',
            accountStatus: "",
            salesModeStatus: "",
            formMemberDetail: {
                album: [],
                auth: {
                    identifier: "",
                    type: "",
                },
                id: '',
                info: {
                    area: '',
                    avatar: '',
                    birthday: '',
                    drink: '',
                    email: '',
                    est_offline: '',
                    favorite: '',
                    height: '',
                    id_card: '',
                    intro: '',
                    invite_code: '',
                    nickname: '',
                    sex: '',
                    smoke: '',
                    weight: '',
                },
                role: '',
            },
            areaList : ['基隆市','台北市','新北市','桃園市','新竹市','新竹縣','苗栗縣','台中市','彰化縣','南投縣','雲林縣','嘉義縣','台南市','高雄市','屏東縣','台東縣','花蓮縣','宜蘭縣','澎湖縣','金門縣','連江縣'],
            formUpdate: {
                nickname: "",
                intro: "",
                sex: "",
                birthday: "",
                email: "",
                area: "",
                height: 0,
                weight: 0,
                favorite: "",
                smoke: 0,
                drink: 0,
            },
            popover: {
                isNicknameVisible: false,
                isIntroVisible: false,
                isSexVisible: false,
                isBirthdayVisible: false,
                isEmailVisible: false,
                isAreaVisible: false,
                isHeightVisible: false,
                isWeightVisible: false,
                isSmokeVisible: false,
                isDrinkVisible: false,
            },
            filePropic: null,
            imgPropic: null,
            fileAlbum: null,
            imgAlbum: null,
            imgAlbumList: null,
            isImgHover: {},
            error: {
                height: false,
                weight: false,
                email: false,
            },
            comfirmDisabled: {
                height: false,
                weight: false,
                email: false,
            },
            showfullImg: false,
            fullImgSrc: null,
            avatarV: false,
        }
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            default: false,
        },
        userId: {
            type: Number,
            default: null,
        },
    },
    components: {
        ModalUpdateIntro,
        ModalUpdateFavorite,
        ModalUpdateStatus,
        ModalUpdateSalesMode,
        ModalRemoveImg,
        MemberStoreList,
        MemberConsumeList,
    },
    mounted() {
        let _this = this;
        document.addEventListener('click', function (event) {
            _this.clickHandler(event);
        });
    },
    beforeDestroy() {
        let _this = this;
        document.removeEventListener('click', function (event) {
            _this.clickHandler(event);
        });
    },
    watch: {
        userId: function() {
            if(this.userId != null){
                this.callApiGetUserDetail();
            }
        },
        accountStatusData: function(newValue, oldValue) {
            console.log(newValue, oldValue)
            if(!this.renderDefault){
                this.renderDefault = true;
            }
        },
        salesModeStatusData: function(newValue, oldValue) {
            console.log(newValue, oldValue)
            if(!this.renderDefault){
                this.renderDefault = true;
            }
        },
        'formUpdate.height': function(newValue) {
            var re = /^[0-9]*$/; // 判斷數字
            if(newValue > 230 || !re.test(newValue)){
                this.error.height = true;
                this.comfirmDisabled.height = true;
            }else{
                this.error.height = false;
                this.comfirmDisabled.height = false;
            }
        },
        'formUpdate.weight': function(newValue) {
            var re = /^[0-9]*$/; // 判斷數字
            if(newValue > 250 || !re.test(newValue)){
                this.error.weight = true;
                this.comfirmDisabled.weight = true;
            }else{
                this.error.weight = false;
                this.comfirmDisabled.weight = false;
            }
        },
        'formUpdate.email': function(newValue) {
            var re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/; // 判斷email格式
            if(!re.test(newValue)){
                this.error.email = true;
                this.comfirmDisabled.email = true;
            }else{
                this.error.email = false;
                this.comfirmDisabled.email = false;
            }
        },
        'imgWidth': function(newValue) { 
            console.log("%c newValue:: " +  newValue, "color:blue;")
        },
    },
    computed: {
        // 性別 => 女=0 f, 男=1 m
        formatSex: function () {
            let sex = this.formMemberDetail.info.sex;
            if(sex == "f"){
                sex = "女性";
            }else{
                sex = "男性";
            }
            return sex;
        },
        // 角色 => 1:一般用戶/2:貴妃
        formatRole: function () {
            let role = this.formMemberDetail.role;
            if(role == "1"){
                role = "一般用戶";
            }else{
                role = "貴妃";
            }
            return role;
        },
        // 抽煙 => 是=1, 否=0
        formatSmoke: function () {
            let smoke = this.formMemberDetail.info.smoke;
            if(smoke == 0){
                smoke = "否";
            }else{
                smoke = "是";
            }
            return smoke;
        },
        // 喝酒 => 是=1, 否=0
        formatDrink: function () {
            let drink = this.formMemberDetail.info.drink;
            if(drink == 0){
                drink = "否";
            }else{
                drink = "是";
            }
            return drink;
        },
        // 帳號類型 => 1:mobile 2:fb
        formatAccountType: function () {
            let accountType = this.formMemberDetail.auth.type;
            if(accountType == 1){
                accountType = "手機";
            }else{
                accountType = "Facebook";
            }
            return accountType;
        },
        // 帳號狀態 => 1:啟用 2:停用
        accountStatusData:{
            get(){
                return this.accountStatus
            },
            set(){
                this.accountStatus = this.formMemberDetail.status;
                if(this.accountStatus == 1){
                    this.accountStatus = true;
                }else{
                    this.accountStatus = false;
                }
            }
        },
        // 業務模式 => 1:啟用 0:停用
        salesModeStatusData:{
            get(){
                return this.salesModeStatus
            },
            set(){
                this.salesModeStatus = this.formMemberDetail.sales_mode;
                if(this.salesModeStatus == 1){
                    this.salesModeStatus = true;
                }else{
                    this.salesModeStatus = false;
                }
            }
        },
        imageUrl: function() {
            return this.formMemberDetail.info.avatar;
        },
    },
    methods: {
        // 相簿 url
        imageAlbumUrl: function(image, userId) {
            return userAlbumUrl(image,userId);
        },
        // click事件監聽
        clickHandler(event) {
            // 以外:關閉
            this.$refs.nickname.doClose();
            this.$refs.sex.doClose();
            this.$refs.birthday.doClose();
            this.$refs.email.doClose();
            this.$refs.area.doClose();
            this.$refs.height.doClose();
            this.$refs.weight.doClose();
            this.$refs.drink.doClose();
            this.$refs.smoke.doClose();

            if(event.target.nodeName == "I"){
                // 以內
                this.$refs[event.target.id].doShow();
            }
        },
        // 取得用戶詳細
        callApiGetUserDetail() {
            loadingStart();
            apiGetUserDetail(
                this.userId
            )
            .then(res=> {
                loadingFinished();
                this.formMemberDetail = res.data.data;

                // 帳號狀態 1:啟用 2:停用
                if(this.formMemberDetail.status == 1){
                    this.accountStatus = true;
                }else{
                    this.accountStatus = false;
                }
                // 業務模式 1:啟用 0:停用
                if(this.formMemberDetail.sales_mode == 1){
                    this.salesModeStatus = true;
                }else{
                    this.salesModeStatus = false;
                }
                // 頭貼
                if(this.formMemberDetail.info.avatar != ""){
                    this.imgPropic = userAvatarUrl(this.formMemberDetail.info.avatar, this.userId)
                }else{
                    this.imgPropic = null;
                }
                // 相簿
                this.imgAlbumList = this.formMemberDetail.album;
                for(var i=0; i<this.imgAlbumList.length; i++){
                    let imgId = this.imgAlbumList[i].id;
                    this.isImgHover[imgId].false;
                }
            })
            .catch(err=> {
                console.log(err);
            })
        },
        // 關閉 modal
        modalClose() {
            this.popover.isNicknameVisible = false;
            this.popover.isIntroVisible = false;
            this.popover.isSexVisible = false;
            this.popover.isBirthdayVisible = false;
            this.popover.isEmailVisible = false;
            this.popover.isAreaVisible = false;
            this.popover.isHeightVisible = false;
            this.popover.isWeightVisible = false;
            this.popover.isSmokeVisible = false;
            this.popover.isDrinkVisible = false;
            this.activeName = 'info';
            this.load.store = false;
            this.load.consume = false;
            this.$emit("isMemberDetailDialogVisible", false);
        },
        // 切換tab
        handleClick(tab, event) {
            console.log(tab, event);
            // 頭貼直橫判斷
            if(tab.name == 'photo'){
                if(this.$refs.avatarImg.height > this.$refs.avatarImg.width){
                    this.avatarV = true;
                }else{
                    this.avatarV = false;
                }
            }else if(tab.name == 'store') {
                this.load.store = true;
                this.load.consume = false;
            }else if(tab.name == 'consume') {
                this.load.store = false;
                this.load.consume = true;
            }
        },
        // 開啟 popover
        openPopOver(key, formUpdateKey, defaultValue){
            this.formUpdate[formUpdateKey] = defaultValue;
            this.popover[key] = true;
        },
        pickerFocus(){
            // 重新render datepicker
            this.$refs.birthdayPicker.mountPicker();
        },
        // 關閉 popover
        closePopOver(key){
            this.popover[key] = false;
        },
        // 更新指定欄位
        updateValue(key, formUpdateKey){
            let updataData = {};
            if(formUpdateKey == "height" || formUpdateKey == "weight"){
                updataData[formUpdateKey] = parseInt(this.formUpdate[formUpdateKey]);
            }else if(formUpdateKey == "birthday"){
               updataData[formUpdateKey] = this.formUpdate[formUpdateKey];
            }else{
                updataData[formUpdateKey] = this.formUpdate[formUpdateKey];
            }

            this.callApiUpdateUser(updataData);
            this.popover[key] = false;
        },
        // 更新用戶指定欄位
        callApiUpdateUser(data){
            loadingStart();
            apiUpdateUser(
                data,
                this.userId
            )
            .then(res=> {
                loadingFinished();
                this.formMemberDetail = res.data.data;
                if(res.data.code == 0){
                    this.$notify({
                        title: '更新資料',
                        message: '更新用戶資料成功',
                        type: 'success'
                    });
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "更新用戶資料失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 更新用戶帳號狀態
        callApiUpdateUserStatus(data){
            loadingStart();
            apiUpdateUserStatus(
                data,
                this.userId
            )
            .then(res=> {
                loadingFinished();
                if(res.data.code == 0){
                    this.$notify({
                        title: '更新資料',
                        message: '更新用戶帳號狀態成功',
                        type: 'success'
                    });
                    // 強迫刷新更新後的資料
                    this.callApiGetUserDetail();
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "更新用戶帳號狀態失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 更新用戶業務模式
        callApiUpdateUserSalesMode(data){
            loadingStart();
            apiUpdateUser(
                data,
                this.userId
            )
            .then(res=> {
                loadingFinished();
                if(res.data.code == 0){
                    this.$notify({
                        title: '更新資料',
                        message: '更新用戶業務模式成功',
                        type: 'success'
                    });
                    // 強迫刷新更新後的資料
                    this.callApiGetUserDetail();
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "更新用戶業務模式失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 開啟 修改用戶介紹modal
        openUpdateIntroModal() {
            this.updateIntroDialogVisible = true;
        },
        // 子元件 傳回 修改用戶介紹modal
        changeUpdateIntroDialogVisible(isVisible){
            this.updateIntroDialogVisible = isVisible;
            // 強迫刷新更新後的資料
            this.callApiGetUserDetail();
        },
        // 開啟 修改用戶喜好modal
        openUpdateFavoriteModal() {
            this.updateFavoriteDialogVisible = true;
        },
        // 子元件 傳回 修改用戶喜好modal
        changeUpdateFavoriteDialogVisible(isVisible){
            this.updateFavoriteDialogVisible = isVisible;
            // 強迫刷新更新後的資料
            this.callApiGetUserDetail();
        },
        // 開啟 修改用戶帳號狀態modal
        openUpdateStatusModal() {
            this.updateStatusDialogVisible = true;
        },
        // 子元件 傳回 修改用戶帳號狀態modal
        changeUpdateStatusDialogVisible(data){
            let isVisible = data.visible;
            let change = data.change;
            this.updateStatusDialogVisible = isVisible;
            if(change){
                // 更新用戶帳號狀態
                let updateData = {}
                // status 邏輯倒過來
                if(this.accountStatus){
                    updateData.status = 2;
                }else{
                    updateData.status = 1;
                }
                this.callApiUpdateUserStatus(updateData)
            }else{
                // 強迫刷新更新後的資料
                this.callApiGetUserDetail();
            }
        },
        // 開啟 修改用戶業務模式modal
        openUpdateSalesModeModal() {
            this.updateSalesModeDialogVisible = true;
        },
        // 子元件 傳回 修改用戶業務模式modal
        changeUpdateSalesModeDialogVisible(data){
            let isVisible = data.visible;
            let change = data.change;
            this.updateSalesModeDialogVisible = isVisible;
            if(change){
                // 更新用戶帳號狀態
                let updateData = {}
                if(this.salesModeStatus){
                    updateData.sales_mode = 0;
                }else{
                    updateData.sales_mode = 1;
                }
                this.callApiUpdateUserSalesMode(updateData)
            }else{
                // 強迫刷新更新後的資料
                this.callApiGetUserDetail();
            }
        },
        // 開啟 刪除相簿照片modal
        openRemoveImgModal() {
            this.removeImgDialogVisible = true;
        },
        // 子元件 傳回 刪除相簿照片modal
        changeRemoveImgDialogVisible(data){
            let isVisible = data.visible;
            let change = data.change;
            this.removeImgDialogVisible = isVisible;
            if(change){
                // 刪除相簿照片
                this.callApiDeleteUserAlbumImgById();
            }else{
                // 強迫刷新更新後的資料
                this.callApiGetUserDetail();
            }
        },
        // 處理頭貼 file
        propicChange(file, fileList) {
            console.log("propic file:", fileList);
            // 預覽
            let reader = new FileReader()
            reader.readAsDataURL(file.raw);

            reader.onload = async (e) => {
                try {
                    this.imgPropic = e.target.result
                } catch (err) {
                    console.log(`Load JSON file error: ${err.message}`)
                }
            }
            // call api 上傳
            this.filePropic = file.raw;
            this.callApiUpdateUserPhote();
        },
        // 更新頭貼api
        callApiUpdateUserPhote(){
            loadingStart();

            let formData = new FormData();
            formData.append('avatar', this.filePropic);
            apiUpdateUserPhote(
                formData,
                this.userId
            )
            .then(res=> {
                loadingFinished();
                console.log(res.data)
                if(res.data.code == 0){
                    this.$notify({
                        title: '更新資料',
                        message: '更新頭貼成功',
                        type: 'success'
                    });
                    this.callApiGetUserDetail();
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "更新頭貼失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 處理相簿 file
        albumChange(file, fileList) {
            console.log(file);
            console.log(fileList);
            console.log("propic file:", fileList);
            if(this.imgAlbumList.length == 4){
                this.$notify.error({
                    title: "更新相簿照片失敗",
                    message: "照片已滿"
                });
            }else{
                // 預覽
                let reader = new FileReader()
                reader.readAsDataURL(file.raw);

                reader.onload = async (e) => {
                    try {
                        this.imgAlbum = e.target.result
                    } catch (err) {
                        console.log(`Load JSON file error: ${err.message}`)
                    }
                }
                // call api 上傳
                this.fileAlbum = file.raw;
                this.callApiUpdateUserAlbum();
            }
        },
        // 刪除用戶相簿指定id
        callApiDeleteUserAlbumImgById(){
            loadingStart();
            if(this.deleteId){
                apiDeleteUserAlbumImgById(
                    this.deleteId,
                    this.userId,
                )
                .then(res=> {
                    loadingFinished();
                    console.log(res.data)
                    if(res.data.code == 0){
                        this.$notify({
                            title: '刪除資料',
                            message: '刪除相簿照片成功',
                            type: 'success'
                        });
                        this.fileAlbum = null;
                        this.imgAlbum = null;
                        this.deleteId = null;
                        this.callApiGetUserDetail();
                    }else{
                        this.$notify.error({
                            title: "發生錯誤",
                            message: "更新相簿照片失敗"
                        });
                    }
                })
                .catch(err=> {
                    console.log(err);
                    loadingFinished();
                    this.$notify.error({
                        title: "發生錯誤",
                        message: err.data.msg
                    });
                })
            }
            
        },
        // 更新相簿api
        callApiUpdateUserAlbum(){
            loadingStart();
            let formData = new FormData();
            formData.append('image', this.fileAlbum);
            apiUpdateUserAlbum(
                formData,
                this.userId
            )
            .then(res=> {
                loadingFinished();
                console.log(res.data)
                if(res.data.code == 0){
                    this.$notify({
                        title: '更新資料',
                        message: '更新相簿照片成功',
                        type: 'success'
                    });
                    this.callApiGetUserDetail();
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "更新相簿照片失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 相簿img hover 效果
        imgMouseEnter(imgId){
            console.log("imgMouseEnter::", imgId)
            this.isImgHover[imgId] = true;
            console.log(this.isImgHover);
        },
        imgMouseLeave(imgId){
            console.log("imgMouseLeave::", imgId)
            this.isImgHover[imgId] = false;
            console.log(this.isImgHover);
        },
        // 相簿刪除
        removeImg(imgId){
            this.showfullImg = false;
            this.fullImgSrc = null;
            
            // modal顯示是否刪除
            this.deleteId = imgId;
            this.openRemoveImgModal();
        },
        // 相片放大顯示
        fullSizePic(imgSrc){
            this.showfullImg = true;
            this.fullImgSrc = imgSrc;
        },
    },
}
</script>
<style scoped lang="scss">
.modal-member-detail {
    .el-dialog__body {
        padding: 30px 50px;
    }
    &__propic {
        display: inline-block;
        margin-top: 20px;
    }
    &__propic-inner {
        text-align: center;
    }
    &__propic-title {
        font-weight: 500;
    }
    &__propic-file {
        position: relative;
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .uploader-icon {
            border: 1px dashed #d9d9d9;
            background-color: #fbfbfb;
            border-radius: 50%;
            font-size: 28px;
            color: #8c939d;
            width: 150px;
            height: 150px;
            line-height: 150px;
            text-align: center;
            margin-top: 12px;
        }
    }
     &__album-file-img-inner {
        display: inline-block;
        cursor: pointer;
        &:hover .modal-member-detail__album-file-remove{
            display: block;
        }
        &:hover .modal-member-detail__album-file-img {
            opacity: 0.5;
        }
    }
    &__album-file-remove{
        cursor: pointer;
        position: absolute;
        top: 0px;
        width: 100px;
        height: 100px;
        z-index: 1;
        display: none;
        i {
            font-size: 28px;
            color: black;
            top: 3px;
            position: absolute;
            right: 3px;
        }
    }
    &__propic-file-img-wrap {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-bottom: 20px;
        overflow: hidden;
        margin-top: 12px;
    }
    &__propic-file-img {
        cursor: pointer;
        width: auto;
        height: 150px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &--v {
            transform: translate(-50%,-50%) scale(1.5);
        }
    }
    &__propic-file-upload {
        border-radius: 4px;
        border: 1px solid $grey;
        padding: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        i {
            margin-right: 8px;
            font-size: 18px;
        }
    }
    &__photo-album {
        margin-top: 50px;
        margin-bottom: 50px;
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: #409EFF;
        }
        .uploader-icon {
            
        }
    }
    &__photo-album-title {
        font-weight: 500;
    }
    &__photo-album-file {
        position: relative;
        display: flex;
        align-items: flex-start;
    }
    &__album-file-upload {
        margin-top: 12px;
    }
    &__album-file-img {
        border: 1px dashed #d9d9d9;
        width: 100px;
        height: 100px;
        margin-right:10px;
        padding: 5px;
        object-fit: cover;
    }
    &__photo-album-file-upload {
        border: 1px dashed #d9d9d9;
        background-color: #fbfbfb;
        color: #8c939d;
        width: 100px;
        height: 100px;
        margin-right: 10px;
        margin-top: 12px;
        div {
            font-size: 14px;
            font-weight: 500;
            i {
                font-size: 18px;
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }
    }
    &__full-img {
        position: fixed;
        z-index: 999999999;
        background-color: rgba(81, 82, 83, 0.9);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: none;
        &--active {
            display: block;
        }
        img {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    &__full-img-close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        i {
            font-size: 30px;
            color: white;
        }
    }
    &__overlay-wrap {
        position: relative;
    }
    &__overlay {
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: gray;
        top: 0;
        left: 0;
        opacity: 0;
    }
}
.el-date-editor.el-input, 
.el-date-editor.el-input__inner {
    width: 100% !important;
}
</style>