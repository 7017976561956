<template>
    <div class="modal-delete-announcement">
        <el-dialog
            :visible.sync="isDialogVisible"
            width="30%"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose" :show-close="false">
            <div slot="title"><i class="el-icon-warning modal-delete-announcement__icon"></i> {{modalTitle}}</div>
            <div class="modal-delete-announcement__form">
                <div class="modal-delete-announcement__form-input">
                    {{modalBody}}
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="deleteAnnouncement()">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiDeleteNews, apiGetNewsDetail } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
    data () {
        return {
            modalTitle: '確定要移除此公告？',
            modalBody: '公告刪除後無法復原',
        }
    },
    props: {
        isDialogVisible: {
            type: Boolean,
            default: false,
        },
        newsId: {
            type: Number,
            default: null,
        },
    },
    watch: {
        newsId: function() {
            if(this.newsId != null){
                this.callApiGetNewsDetail();
            }
        }
    },
    methods: {
        // 編輯
        deleteAnnouncement(){
            // call api 編輯公告
            this.callApiDeleteNews();
        },
        // 編輯公告api
        callApiDeleteNews(){
            loadingStart();
            apiDeleteNews(this.newsId)
            .then(res=> {
                loadingFinished();
                this.modalClose();
                if(res.data.code == 0){
                    this.$notify({
                        title: '刪除公告',
                        message: '刪除公告成功',
                        type: 'success'
                    });
                    this.$emit("isDeleteNewsDialogVisible", false);
                }else{
                    this.$notify.error({
                        title: "發生錯誤",
                        message: "刪除公告失敗"
                    });
                }
            })
            .catch(err=> {
                console.log(err);
                loadingFinished();
                this.$notify.error({
                    title: "發生錯誤",
                    message: err.data.msg
                });
            })
        },
        // 取得 公告詳細
        callApiGetNewsDetail() {
            loadingStart();
            apiGetNewsDetail(
                this.newsId
            )
            .then(res=> {
                loadingFinished();
                console.log(res.data.data)
            })
            .catch(err=> {
                console.log(err);
                this.modalTitle = "公告不存在";
                this.modalBody = "";
                loadingFinished();
            })
        },
        // 關閉 modal
        modalClose() {
            this.$emit("isDeleteNewsDialogVisible", false);
        },
    },
}
</script>
<style scoped lang="scss">
.el-dialog {
    >div {
        display: none !important;
    }
}
.modal-delete-announcement {
    &__icon {
        color: $orange;
    }
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>