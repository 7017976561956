<template>
    <div class="modal-update-sales-mode">
        <el-dialog
            :key="'編輯業務模式'"
            title="編輯業務模式"
            :visible.sync="isDialogVisible"
            width="30%"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-update-sales-mode__form form-wrap">
                <div class="modal-update-sales-mode__form-input form-input form-textarea">
                    確定要變更業務模式？
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="updateConfirm()">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
        formLabelWidth: "50",
        comfirmDisabled: false,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    },
    userId: {
        type: Number,
        default: null,
    },
    oldValue: {
        type: Number,
        default: null,
    }
  },
    computed: {
        status:  {
            get: function () {
                return this.oldValue
            },
            set: function (newValue) {
                this.oldValue = newValue
            }
        }
    },
    watch: { 
    },
  methods: {
    // 編輯
    updateConfirm(){
        this.$emit("isUpdateSalesModeDialogVisible", {visible: false, change: true});
    },
    // 關閉 modal
    modalClose() {
        this.$emit("isUpdateSalesModeDialogVisible", {visible: false, change: false});
    },
  },
}
</script>
<style scoped lang="scss">
.modal-update-sales-mode {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>