import { render, staticRenderFns } from "./ChatRankMonthlyList.vue?vue&type=template&id=f6c2a0cc&scoped=true&"
import script from "./ChatRankMonthlyList.vue?vue&type=script&lang=js&"
export * from "./ChatRankMonthlyList.vue?vue&type=script&lang=js&"
import style0 from "./ChatRankMonthlyList.vue?vue&type=style&index=0&id=f6c2a0cc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6c2a0cc",
  null
  
)

export default component.exports