import { render, staticRenderFns } from "./modalMemberDetail.vue?vue&type=template&id=cb85c1ce&scoped=true&"
import script from "./modalMemberDetail.vue?vue&type=script&lang=js&"
export * from "./modalMemberDetail.vue?vue&type=script&lang=js&"
import style0 from "./modalMemberDetail.vue?vue&type=style&index=0&id=cb85c1ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb85c1ce",
  null
  
)

export default component.exports