<template>
    <div class="modal-update-password">
        <el-dialog
            :key="'變更密碼'"
            title="變更密碼"
            :visible.sync="isDialogVisible"
            width="40%"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-update-password__form form-wrap">
                <div class="modal-update-password__form-input form-input" :class="{'error': error.new_pwd}">
                    <label>輸入密碼：</label>
                    <el-input v-model="formUpdatePassword.new_pwd" autocomplete="off" placeholder="請輸入密碼" show-password></el-input>
                    <div class="error-msg">8~16字元</div>
                </div>
                <div class="modal-update-password__form-input form-input" :class="{'error': error.confirm_pwd}">
                    <label>確認密碼：</label>
                    <el-input v-model="formUpdatePassword.confirm_pwd" autocomplete="off" placeholder="請再次輸入密碼" show-password></el-input>
                    <div class="error-msg">8~16字元且兩次密碼必須輸入相同</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="updatePassword()" :disabled="comfirmDisabled">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiUpdateUserPwd } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
  data () {
    return {
        formLabelWidth: "50",
        formUpdatePassword: {
            new_pwd: '',
            confirm_pwd: '',
        },
        error: {
            new_pwd: false,
            confirm_pwd: false,
        },
        comfirmDisabled: true,
        firstLoadData: true,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    },
    userId: {
        type: Number,
        default: null,
    }
  },
  watch: {
      formUpdatePassword: {
        handler: function (newValue) {
            if(this.firstLoadData){
                this.firstLoadData = false;
            }else{
                if(newValue.new_pwd){
                    if(newValue.new_pwd.length >= 8 && newValue.new_pwd.length <=16 ){
                        this.error.new_pwd = false;
                    }else{
                        this.error.new_pwd = true;
                    }
                }
                if(newValue.confirm_pwd){
                    if(newValue.confirm_pwd.length >= 8 && newValue.confirm_pwd.length <=16 && (this.formUpdatePassword.new_pwd == this.formUpdatePassword.confirm_pwd) ){
                        this.error.confirm_pwd = false;
                    }else{
                        this.error.confirm_pwd = true;
                    }
                }
                // 驗證所有欄位
                if(this.error.new_pwd == false && 
                    this.error.confirm_pwd == false && 
                    this.formUpdatePassword.new_pwd == this.formUpdatePassword.confirm_pwd){
                    this.comfirmDisabled = false;
                }else{
                    this.comfirmDisabled = true;
                }
            }
        },
        deep: true,
      },
  },
  methods: {
    // 變更密碼
    updatePassword(){
        // 檢核input
        this.callApiUpdateUserPwd(this.formUpdatePassword);
    },
    // 關閉 modal
    modalClose() {
        this.clearData();
        this.$emit("isUpdatePasswordDialogVisible", false);
    },
    // 變更密碼api
    callApiUpdateUserPwd(data){
        console.log(data);
        loadingStart();
        apiUpdateUserPwd(
            data,
            this.userId
        )
        .then(res=> {
            loadingFinished();
            this.modalClose();
            if(res.data.code == 0){
                this.$notify({
                    title: '資料變更',
                    message: '更新密碼成功',
                    type: 'success'
                });
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "更新密碼失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
    // 清除輸入匡資料
    clearData(){
        this.error.new_pwd = false;
        this.error.confirm_pwd = false;
        this.formUpdatePassword.new_pwd = '';
        this.formUpdatePassword.confirm_pwd = '';
    },
  },
}
</script>
<style scoped lang="scss">
.modal-update-password {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>