import * as types from './mutations_type.js'

// state
export const state = {
  token: null,
  errorModal400Display: false,
}

// mutations
export const mutations = {
  // action 發出 commit 會對應到 mutation 使用的是 Object key 方式
  // 在 mutation 改變 state

  [types.TOKEN] (state, {token}) {
    state.token = token;
  },
  [types.ERRORMODAL400] (state, {status}) {
    state.errorModal400Display = status;
  },
}