<template>
    <div class="modal-update-intro">
        <el-dialog
            :key="'編輯介紹'"
            title="編輯介紹"
            :visible.sync="isDialogVisible"
            width="40%"
            :modalAppendToBody="false"
            :appendToBody="true"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-update-intro__form form-wrap">
                <div class="modal-update-intro__form-input form-input form-textarea" :class="{'error': error.intro}">
                    <label>介紹：</label>
                    <el-input v-model="intro" type="textarea" 
                    :autosize="{ minRows: 3, maxRows: 8}" autocomplete="off" placeholder="請輸入介紹，最多500字"
                    maxlength="500" show-word-limit></el-input>
                    <div class="error-msg">最多500字</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="updateIntro()" :disabled="comfirmDisabled">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiUpdateUser } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
  data () {
    return {
        formLabelWidth: "50",
        error: {
            intro: false,
        },
        comfirmDisabled: false,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    },
    userId: {
        type: Number,
        default: null,
    },
    oldValue: {
        type: String,
        default: "",
    }
  },
  computed: {
      intro:  {
        get: function () {
            return this.oldValue
        },
        set: function (newValue) {
           this.oldValue = newValue
        }
      }
  },
  watch: { 
        intro : function(newValue) { 
            if(newValue.length > 500){
                this.error.intro = true;
                this.comfirmDisabled = true;
            }else{
                this.error.intro = false;
                this.comfirmDisabled = false;
            }
        }
    },
  methods: {
    // 編輯介紹
    updateIntro(){
        this.callApiUpdateUser({ intro: this.oldValue });
    },
    // 關閉 modal
    modalClose() {
        this.$emit("isUpdateIntroDialogVisible", false);
    },
    // 更新介紹
    callApiUpdateUser(data){
        loadingStart();
        apiUpdateUser(
            data,
            this.userId
        )
        .then(res=> {
            loadingFinished();
            this.$emit("isUpdateIntroDialogVisible", false);
            if(res.data.code == 0){
                this.$notify({
                    title: '更新資料',
                    message: '更新用戶資料成功',
                    type: 'success'
                });
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "更新用戶資料失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
  },
}
</script>
<style scoped lang="scss">
.modal-update-intro {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>