<template>
  <div class="member-list layout-grid">
    <!-- 篩選資料 -->
    <div class="filter">
      <div class="left">
        <div class="input-wrap">
          <label>用戶ID：</label>
          <el-input placeholder="請輸入用戶ID" v-model="formFilter.userId"></el-input>
        </div>
        <div class="select-wrap">
          <label>角色：</label>
          <el-select v-model="formFilter.role" placeholder="全部">
            <el-option
              v-for="role in filter.roleList"
              :key="role.value"
              :label="role.label"
              :value="role.value">
            </el-option>
          </el-select>
        </div>
        <div class="select-wrap">
          <label>帳號狀態：</label>
          <el-select v-model="formFilter.status" placeholder="全部">
            <el-option
              v-for="status in filter.statusList"
              :key="status.value"
              :label="status.label"
              :value="status.value">
            </el-option>
          </el-select>
        </div>
        <div class="filter-btn-row">
          <el-button type="primary" @click="filterSearch()">查詢</el-button>
          <el-button @click="filterReset()">重置</el-button>
        </div>
      </div>
      <div class="right">
        <div class="filter-btn-row">
          <el-button type="primary" @click="openAddMemberModal()">＋用戶</el-button>
        </div>
      </div>
    </div>
    <!-- 資料列表 -->
    <div class="data-list">
      <el-table
        :data="displayData">
        <el-table-column
          prop="id"
          label="用戶ID">
          <template slot-scope="scope">
            <el-button type="text" @click="openDetailModal(scope.row)">{{ scope.row.id }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="info.nickname"
          label="暱稱">
        </el-table-column>
        <el-table-column
          label="角色"
          :formatter="roleFormatter">
        </el-table-column>
        <el-table-column
          label="帳號狀態"
          :formatter="accountStatusFormatter">
        </el-table-column>
        <el-table-column
          prop="create_at"
          label="註冊時間"
          sortable>
        </el-table-column>
        <el-table-column
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button @click="openChangePwModal(scope.row)" type="text">變更密碼</el-button>
        </template>
      </el-table-column>
      </el-table>
    </div>
    <!-- 分頁 -->
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20]"
        :page-size="formFilter.pageSize"
        layout="prev, pager, next, sizes"
        :total="formFilter.totalPage">
      </el-pagination>
    </div>
    <!-- 新增用戶 modal -->
    <modal-add-member :isDialogVisible="addMemberDialogVisible" v-on:isAddMemberDialogVisible="changeAddMemberDialogVisible"/>
    <!-- 變更密碼 modal -->
    <modal-update-password :userId="updatePasswordId" :isDialogVisible="updatePasswordDialogVisible" v-on:isUpdatePasswordDialogVisible="changeUpdatePasswordDialogVisible"/>
    <!-- 用戶詳細 modal -->
    <modal-member-detail :userId="memberDetailId" :isDialogVisible="memberDetailDialogVisible" v-on:isMemberDetailDialogVisible="changeMemberDetailDialogVisible" />
  </div>
</template>

<script>
import { apiGetUserList, apiGetUserListByUserId } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'
import ModalAddMember from '@/components/memberList/modalAddMember'
import ModalUpdatePassword from '@/components/memberList/modalUpdatePassword'
import ModalMemberDetail from '@/components/memberList/modalMemberDetail'

export default {
  data () {
    return {
      addMemberDialogVisible: false,
      updatePasswordDialogVisible: false,
      updatePasswordId: null,
      memberDetailDialogVisible: false,
      memberDetailId: null, 
      formFilter: {
        userId: '',
        role: '',
        status: '',
        page: 1,
        pageSize: 5,
        totalPage: 0,
      },
      filter: {
        roleList: [
          {
            value: '',
            label: '全部'
          },
          {
            value: 1,
            label: '一般用戶'
          },
          {
            value: 2,
            label: '貴妃'
          },
        ],
        statusList: [
          {
            value: '',
            label: '全部'
          },
          {
            value: 1,
            label: '啟用'
          },
          {
            value: 2,
            label: '停用'
          },
        ],
      },
      tableDataList: [],
      currentPage: 1,
    }
  },
  mounted() {
    this.callApiGetUserList();
  },
  components: {
    ModalAddMember,
    ModalUpdatePassword,
    ModalMemberDetail,
  },
  computed: {
    displayData() {
      return this.tableDataList;
    }
  },
  watch: {
    // 狀態篩選改變 清空page條件
    'formFilter.status': function() {
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
    },
    // 角色篩選改變 清空page條件
    'formFilter.role': function() {
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
    },
  },
  methods: {
    // 開啟 新增用戶 modal
    openAddMemberModal() {
      this.addMemberDialogVisible = true;
    },
    // 子元件 傳回 新增用戶modal狀態
    changeAddMemberDialogVisible(isVisible){
      this.callApiGetUserList();
      this.addMemberDialogVisible = isVisible;
    },
    // 開啟 變更密碼 modal
    openChangePwModal(row) {
      this.updatePasswordDialogVisible = true;
      this.updatePasswordId = row.id;
    },
    // 子元件 傳回 變更密碼modal狀態
    changeUpdatePasswordDialogVisible(isVisible){
      this.updatePasswordDialogVisible = isVisible;
    },
    // 開啟 用戶詳細 modal
    openDetailModal(row){
      setTimeout(()=>{ 
        this.memberDetailDialogVisible = true; 
      }, 100);
      this.memberDetailId = row.id;
    },
    // 子元件 傳回 用戶詳細modal狀態
    changeMemberDetailDialogVisible(isVisible){
      this.callApiGetUserList();
      this.memberDetailDialogVisible = isVisible;
    },
    // 切換每頁顯示的數量
    handleSizeChange(val) {
      this.currentPage = 1;
      this.formFilter.pageSize = val;
      this.formFilter.page = 1;

      if(this.formFilter.userId != ""){
        this.formFilter.page = 1;
        this.formFilter.pageSize = 5;
        this.formFilter.totalPage = 1;
        this.callApiGetUserListByUserId();
      }else{
        this.callApiGetUserList()
      }
    },
    // 切換當前頁數
    handleCurrentChange(val) {
      this.currentPage = val;
      this.formFilter.page = val;
      if(this.formFilter.userId != ""){
        this.formFilter.page = 1;
        this.formFilter.pageSize = 5;
        this.formFilter.totalPage = 1;
        this.callApiGetUserListByUserId();
      }else{
        this.callApiGetUserList()
      }
    },
    // 資料篩選 查詢 
    filterSearch(){
      this.currentPage = 1;
      // 用戶ID 搜尋
      if(this.formFilter.userId != ""){
        this.formFilter.page = 1;
        this.formFilter.pageSize = 5;
        this.formFilter.totalPage = 1;
        this.callApiGetUserListByUserId();
      }else{
        this.formFilter.page = 1;
        this.formFilter.pageSize = 5;
        // 角色/帳號狀態 篩選
        this.callApiGetUserList();
      }
    },
    // 資料篩選 重置
    filterReset(){
      this.formFilter.userId = '';
      this.formFilter.role = '';
      this.formFilter.status = '';
      this.formFilter.page = 1;
      this.formFilter.pageSize = 5;
      this.currentPage = 1;
      this.callApiGetUserList();
    },
    // 角色 欄位format顯示
    roleFormatter (row) {
      if(row.role == 1){
        return "用戶"
      }
      if(row.role == 2){
        return "貴妃"
      }
    },
    // 帳號狀態 欄位format顯示
    accountStatusFormatter (row) {
      if(row.status == 1){
        return "啟用"
      }
      if(row.status == 2){
        return "停用"
      }
    },
    // 取得用戶列表
    callApiGetUserList(){
      loadingStart();

      let param = "";
      // 頁碼/頁數
      param += `page=${this.formFilter.page}&size=${this.formFilter.pageSize}`;

      // 角色
      if(this.formFilter.role != ''){
        param += `&role=${this.formFilter.role}`;
      }
      // 帳號狀態
      if(this.formFilter.status != ''){
        param += `&status=${this.formFilter.status}`;
      }

      apiGetUserList(
        param
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0){
          this.tableDataList = res.data.data;
          this.formFilter.totalPage = res.data.paging.total_page * res.data.paging.size;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    },
    // 查詢用戶id
    callApiGetUserListByUserId(){
      loadingStart();
      apiGetUserListByUserId(
        this.formFilter.userId
      )
      .then(res=> {
        loadingFinished();
        if(res.data.code == 0 || res.data.code == 9002){
            let tmp = [];
            if(res.data.data != {} && res.data.data != null){
              tmp.push(res.data.data)
            }
            this.tableDataList = tmp;
        }else{
            this.$notify.error({
                title: "發生錯誤",
                message: "系統發生非預期錯誤"
            });
        }
      })
      .catch(err=> {
        console.log(err);
        loadingFinished();
        this.$notify.error({
            title: "發生錯誤",
            message: err.data.msg
        });
      })
    }
  },
}
</script>

<style scoped lang="scss">
  .member-list {
  }
</style>
