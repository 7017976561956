<template>
    <div class="error-modal-400">
        <el-dialog
            title="登入權限過期或系統發生錯誤"
            :visible.sync="isDialogVisible"
            width="30%"
            :modalAppendToBody="false"
            :before-close="routeToLogin">
            <div class="error-modal-400__form">
                <label>系統將自動登出，請重新登入</label>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="routeToLogin()">確 認</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { removeToken, removeUserInfo, loadingFinished } from '@/function'
import store from "@/store";

export default {
  data () {
    return {
        
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    },
  },
  methods: {
    // 導到登入頁
    routeToLogin(){
        // 刪除localstorage資料
        removeToken();
        removeUserInfo();
        loadingFinished();
        store.dispatch('actionSetErrorModal400Display', false);
        // 強迫重新渲染
        // this.$forceUpdate();
        // 導到登入頁
        this.$router.push({ name: 'Login'});
    },
  },
}
</script>
<style scoped lang="scss">
.error-modal-400 {
    &__form {
        label {
            text-align: left;
        }
        div {
            font-weight: 500;
        }
    }
}
</style>