<template>
    <div class="layout">
        <div class="layout-wrap">
        <!-- <el-container class="layout-wrap" :class="{collapsed: sideBarStatus}"> -->
            <el-container class="content">
                <!-- website name -->
                <el-header class="website">冰山撞甜心管理系統</el-header>
                <!-- sidebar -->
                <sidebar></sidebar>
                <!-- <sidebar :sideBarIsCollapse="sideBarStatus"></sidebar> -->
                <!-- main -->
                <el-main class="main">
                <el-header class="nav">
                    <!-- <i class="el-icon-s-fold flod-icon" @click="collapseSideBar()"></i> -->
                    <div></div>
                    <div class="user-info">
                      <!-- <img src="https://fakeimg.pl/30"/>
                      <label>{{userInfo.nickname}}</label> -->
                      <el-dropdown trigger="click">
                        <div class="el-dropdown-link">
                          <el-avatar icon="el-icon-user-solid"></el-avatar>
                          <label>{{userInfo.nickname}}</label>
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown" style="width: 100px;text-align:center;">
                          <el-dropdown-item  @click.native="logOut">登出</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                </el-header>
                <!-- view -->
                <router-view></router-view>
                </el-main>
            </el-container>
        </div>
    </div>
</template>

<script>
import { apiUserLogout } from "@/api/api"; 
import { getUserInfo, removeToken, removeUserInfo, loadingStart, loadingFinished } from '@/function'
import Sidebar from '@/components/layout/Sidebar'

export default {
  data () {
    return {
    }
  },
  components: {
    Sidebar
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logOut(){
      // call api 登出 
      this.callApiUserLogout()
    },
    // 登出
    callApiUserLogout(){
        loadingStart();
        apiUserLogout()
        .then(res=> {
            loadingFinished();
            if(res.data.code == 0){
              // 移除存的資料
              removeToken()
              removeUserInfo();
              console.log(res.data)
              // 導回登入頁
              this.$router.push({ name: 'Login'});
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "登出失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
  },
  computed: {
    userInfo: function () {
      return JSON.parse(getUserInfo());
    }
  }
}
</script>
<style scoped lang="scss">
.layout {
    .el-header.website {
        transition: width .5s ease;
        width: 300px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        color: $white;
        font-weight: 500;
        height: 80px !important;
    }
    .el-submenu {
        &__title {
            text-align: left;
        }
    }
    .el-main.main {
        padding: 0px;
        margin-left: 299px;
        position: fixed;
        width: calc(100% - 299px);
        // overflow: scroll;
        background-color: $light-grey;
        height: 100%;
        .el-header.nav {
            position: fixed;
            background-color: white;
            width: calc(100% - 299px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px !important;
            z-index: 4;
            .flod-icon {
                padding: 5px;
                width: 30px;
                height: 30px;
                font-size: 20px;
                cursor: pointer;
            }
            .user-info {
              display: flex;
              align-items: center;
              img {
                margin-right: 8px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
              }
            }
        }
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      // color: #409EFF;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    // .collapsed {
    //     .el-header.website {
    //         width: 36px;
    //     }
    //     .el-main.main {
    //         margin-left: 35px;
    //         width: calc(100% - 35px);
    //         .el-header.nav {
    //             width: calc(100% - 35px);
    //         }
    //     }
    //     .flod-icon {
    //         transform: rotate(180deg);
    //     }
    // }
    .el-avatar {
      width: 30px;
      height: 30px;
      font-size: 14px;
      line-height: 30px;
      margin-right: 8px;
    }
}
</style>