<template>
  <div class="login">
    <div class="wrapper">
      <h3>冰山撞甜心管理系統</h3>
      <div class="input-wrap">
        <label>信箱：</label>
        <el-input placeholder="請輸入帳號" v-model="form.email"></el-input>
      </div>
      <div class="input-wrap">
        <label>密碼：</label>
        <el-input placeholder="請輸入密碼" v-model="form.password" show-password></el-input>
      </div>
      <div class="btn-wrap">
        <el-button type="primary" @click="login()">登入</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { setUserInfo, setToken, loadingStart, loadingFinished } from '@/function'
import { apiUserLogin } from "@/api/api"; 

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: "",
        password: "",
      }
    }
  },
  methods: {
    ...mapActions([
      'actionSetToken',
    ]),
    // 登入
    login() {
      var email = this.form.email;
      var password = this.form.password;

      if(email != "" && password != ""){
        loadingStart();
        // api 登入
        apiUserLogin({
          email: email,
          password: password
        })
        .then(res=> {
            loadingFinished();
            this.actionSetToken(res.data.token);
            setToken(res.data.token);
            setUserInfo(JSON.stringify(res.data.data));
            this.$router.push({ name: 'MemberList'});
        })
        .catch(err=> {
          loadingFinished();
          if(err.data.code == 1100){
            this.$notify.error({
              title: `發生錯誤`,
              message: '帳號或密碼錯誤',
            });
          }else{
            this.$notify.error({
              title: `發生錯誤`,
              message: '請重新輸入帳號或密碼',
            });
          }
        })
      }else{
        console.log("請輸入帳號或密碼");

        this.$notify.error({
          title: `發生錯誤`,
          message: '請輸入帳號或密碼',
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100%;
  height: 100%;
  background-color: $light-grey;
  .input-wrap {
    margin-bottom: 20px;
  }
  .wrapper {
    width: 300px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 20px 30px 20px;
    border-radius: 6px;
    box-shadow: 2px 2px 8px 0 rgba(41, 38, 37, 0.08);
    background-color: $white;

    h3 {
      font-weight: 500;
      margin-bottom: 30px;
    }
    .btn-wrap {

    }
  }
}
</style>