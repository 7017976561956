<template>
    <div class="modal-add-announcement">
        <el-dialog
            :key="'新增公告'"
            title="新增公告"
            :visible.sync="isDialogVisible"
            width="50%"
            :modalAppendToBody="false"
            :close-on-click-modal="false"
            :before-close="modalClose">
            <div class="modal-add-announcement__form form-wrap">
                <div class="modal-add-announcement__form-input form-input" :class="{'error': error.subject}">
                    <label>標題：</label>
                    <el-input v-model="formAddNews.subject" autocomplete="off" placeholder="請輸入公告標題"></el-input>
                    <div class="error-msg">必填</div>
                </div>
                <div class="modal-add-announcement__form-input modal-add-announcement__form-input--body form-input" :class="{'error': error.body}">
                    <label>內容：</label>
                    <el-input v-model="formAddNews.body" type="textarea" 
                    :autosize="{ minRows: 3, maxRows: 8}" autocomplete="off" placeholder="請輸入公告內容"></el-input>
                    <div class="error-msg">必填</div>
                </div>
                <div class="modal-add-announcement__form-input form-input">
                    <label>類型：</label>
                    <div class="form-radio-wrap">
                        <el-radio v-model="formAddNews.category" :label="1">一般</el-radio>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modalClose()">取 消</el-button>
                <el-button type="primary" @click="addNewsModal()" :disabled="comfirmDisabled">確 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { apiCreateNews } from "@/api/api"; 
import { loadingStart, loadingFinished } from '@/function'

export default {
  data () {
    return {
        formAddNews: {
            subject: '',
            body: '',
            category: 1,
        },
        error: {
            subject: '',
            body: '',
        },
        comfirmDisabled: false,
    }
  },
  props: {
    isDialogVisible: {
        type: Boolean,
        default: false,
    }
  },
  watch: {
    formAddNews: {
        handler: function (newValue) {
            if(newValue.subject){
                this.error.subject = false;
            }
            if(newValue.body){
                this.error.body = false;
            }
        },
        deep: true,
    },
  },
  mounted() {
      this.clearData();
  },
  methods: {
    // 新增公告
    addNewsModal(){
        if(this.checkRequired() ){
            // call api 新增用戶
            this.callApiCreateNews();
        }
    },
    // 關閉 modal
    modalClose() {
        this.clearData();
        this.$emit("isAddNewsDialogVisible", false);
    },
    // 清除資料
    clearData(){
        this.formAddNews.subject = "";
        this.formAddNews.body = "";
        this.formAddNews.category = 1;
        this.comfirmDisabled = false;
    },
    // 新增用戶api
    callApiCreateNews(){
        loadingStart();
        apiCreateNews(
            this.formAddNews,
        )
        .then(res=> {
            loadingFinished();
            this.modalClose();
            if(res.data.code == 0){
                this.$notify({
                    title: '新增公告',
                    message: '新增公告成功',
                    type: 'success'
                });
            }else{
                this.$notify.error({
                    title: "發生錯誤",
                    message: "新增公告失敗"
                });
            }
        })
        .catch(err=> {
            console.log(err);
            loadingFinished();
            this.$notify.error({
                title: "發生錯誤",
                message: err.data.msg
            });
        })
    },
    // 驗證必填欄位
    checkRequired(){
        if(this.formAddNews.subject && this.formAddNews.subject != ''){
            this.error.subject = false;
        }else{
            this.error.subject = true;
        }
        if(this.formAddNews.body  && this.formAddNews.body != ''){
            this.error.body = false;
        }else{
            this.error.body = true;
        }

        // 驗證所有欄位
        if(this.error.subject == false &&
            this.error.body == false){
            return true;
        }else{
            return false;
        }
    },
  },
}
</script>
<style scoped lang="scss">
.modal-add-announcement {
    &__form {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }
    .form-radio-wrap {
        width: 100%;
    }
    &__form-input {
        label {
            white-space: nowrap;
        }
        &--body {
            align-items: flex-start !important;
        }
    }
}
</style>